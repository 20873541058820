import React from 'react';
import logo from './Mallu Bazzar Logo_ F_White.png';
import { useNavigate } from 'react-router-dom';
const Footer = () => {
  const navigate = useNavigate();
    const navigationPage = (category) => {
      navigate(`/search-items?query=${encodeURIComponent(category)}`);
    };
  return (
    <footer class="w-full pb-10 bg-[#1a572b]">
      <div class="mx-auto max-w-8xl px-4 sm:px-6 lg:px-8">
        <div class="grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-6 gap-3 md:gap-8 py-10 max-sm:max-w-sm max-sm:mx-auto gap-y-8">
          <div class="col-span-full mb-2 lg:col-span-2 lg:mb-0">
            <a href="/" class="flex justify-center lg:justify-start">
              <img
                src={logo}
                class="w-34 h-20"
                alt="Online Grocery Store Logo"
              />
            </a>
            <p class="py-4 text-sm text-white lg:max-w-xs text-center lg:text-left">
              "Delivering authentic Kerala groceries to your doorstep – Fresh,
              Traditional, and Full of Flavor!"
            </p>
            <p className="text-sm text-white">
              Email: Info.mallubazar@gmail.com
              <br />
              Whatsapp:- +1226-898-4298
            </p>
          </div>

          <div class="lg:mx-auto text-left">
            <h4 class="text-lg text-white font-medium mb-7">Shop With Us</h4>
            <ul class="text-sm transition-all duration-500">
            

               <li class="mb-2">
                <a href="/About" class="text-gray-200 hover:text-white">
                  About Us
                </a>
              </li>

               <li className='mb-2' >
                <a href="/faq" class="text-gray-200 hover:text-white">
                  FAQ
                </a>
              </li>

                    <li className='mb-2' >
                <a href="/Terms" class="text-gray-200 hover:text-white">
                   Terms & Conditions
                </a>
              </li>

                    <li className='mb-2' >
                <a href="/policy" class="text-gray-200 hover:text-white">
                  Cancellation & Refund Policy
                </a>
              </li>
            

             
             
             
              
             
            </ul>
          </div>

          <div class="lg:mx-auto text-left">
            <h4 class="text-lg text-white font-medium mb-7">Categories</h4>
            <ul class="text-sm transition-all duration-500">
             <li class="mb-2">
                <div
                  // onClick={() => navigationPage("fruits")}
                  class="text-gray-200 hover:text-white cursor-pointer"
                >
                  All Products
                </div>
              </li>
              <li class="mb-2">
                <div
                  onClick={() => navigationPage("rice")}
                  class="text-gray-200 hover:text-white cursor-pointer"
                >
                  Rice
                </div>
              </li>
              <li class="mb-2">
                <div
                  onClick={() => navigationPage("Fish")}
                  class="text-gray-200 hover:text-white cursor-pointer"
                >
                  Fish
                </div>
              </li>
              <li>
                <div
                  onClick={() => navigationPage("Snacks")}
                  class="text-gray-200 hover:text-white cursor-pointer"
                >
                  Snacks
                </div>
              </li>
            </ul>
          </div>

          <div class="lg:mx-auto text-left">
            <h4 class="text-lg text-white font-medium mb-7">
              Customer Resources
            </h4>
            <ul class="text-sm transition-all duration-500">
              <li class="mb-2">
                <a href="/profile" class="text-gray-200 hover:text-white">
                  Profile
                </a>
              </li>
              <li class="mb-2">
                <a href="/cart" class="text-gray-200 hover:text-white">
                  Cart
                </a>
              </li>
              <li>
                <a href="/whislist" class="text-gray-200 hover:text-white">
                  Wishlist
                </a>
              </li>
            </ul>
          </div>

          <div class="lg:mx-auto text-left">
            <h4 class="text-lg text-white font-medium mb-7">Connect With Us</h4>
            <ul class="text-sm transition-all duration-500">
              <li class="mb-2">
                <a href="/" class="text-gray-200 flex hover:text-white">

                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-facebook" viewBox="0 0 16 16">
  <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951"/>
</svg>
                 <span className='ml-2 mb-1' > Facebook</span> 
                </a>
              </li>
              <li class="mb-2">
                <a
                  href="https://www.instagram.com/mallubazar_renest/?igsh=MWg3bWk0aGl1bTdkcw%3D%3D#"
                  class="text-gray-200 flex hover:text-white"
                >
                <svg className='' xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-instagram" viewBox="0 0 16 16">
  <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334"/>
</svg>
                 <span className="ml-2 mb-1"> Instagram</span> 
                </a>
              </li>
              <li>
                <a href="/" class="text-gray-200 flex hover:text-white">

                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-twitter-x" viewBox="0 0 16 16">
  <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"/>
</svg>
                 <span className="ml-2"> Twitter</span>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div class="py-6 border-t border-gray-300">
          <div class="flex items-center justify-center flex-col lg:justify-between lg:flex-row">
            <span class="text-sm text-center text-gray-200">
              ©{" "}
              <a href="/" class="underline">
                Mallu Bazaar
              </a>{" "}
              2024, All rights reserved.
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
