import {  doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { auth, db } from '../../Admin/Config/Config';
import Loading from '../../assets/Loading/Loading';

export default function OrderHistory(user) {
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const [submitted, setSubmitted] = useState(true);
  const [orderDetails, setOrderDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [reviewLoading, setReviewLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  // =============================== Control back button =============================== //

  useEffect(() => {
    const handlePopState = (event) => {
      // Custom logic when back button is pressed
      navigate("/profile");
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (!location.state) {
      navigate("/profile");
    } 
    fetchOrder();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const fetchOrder = async () => {
     await fetchFeedback();
    const docRef = doc(db, "orders", location.state.order.id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setOrderDetails(docSnap.data());
      setLoading(false);
    }
  };

  const handleRating = (value) => {
    setRating(value);
  };
const fetchFeedback = async () => {
  const ref = doc(db, "orders", location.state.order.id);
  const docSnap = await getDoc(ref);
  if (docSnap.exists()) {
    setSubmitted(docSnap.data().feedback?true:false);
  }

}
// =============================== Submit feedback ================================ //
 const handleEditReview = () => {
  setComment(orderDetails.feedback?orderDetails.feedback:"");
  setRating(orderDetails.user_rating?orderDetails.user_rating:0);
  setSubmitted(false);
 }
  const handleSubmit = async() => {

    if (rating > 0 && comment.trim()) {
      setReviewLoading(true);
      await updateDoc(doc(db, "orders", location.state.order.id), {
        user_rating: rating,
        feedback: comment,
      });
      await setDoc(
        doc(db, "customer_reviews", location.state.order.id),
        {
          user_rating: rating,
          feedback: comment,
          user_id: auth.currentUser.uid,
          email: auth.currentUser.email,
          display: false,
          date: new Date(),
          user_name: user.name,
          new: true,
          order: 1,
        },
        {
          merge: true,
        }
      );
      setReviewLoading(false);
      setSubmitted(true);

    } else {
      alert("Please select a rating and add a comment before submitting.");
    }
  };

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  // Function to toggle popup visibility
  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className="min-h-screen py-12 bg-gray-100">
          {orderDetails ? (
            <div className="container mx-auto shadow py-6 px-6 max-w-7xl bg-white text-center">
              <div className="mt-6 text-left">
                <div className="flex justify-between ">
                  <p className="text-lg justify-between font-medium text-gray-900">
                    Order Id : #{orderDetails.order_id}
                  </p>
                  <button
                    className="text-white px-2 rounded-lg py-2 bg-green-700"
                    onClick={togglePopup}
                  >
                    {" "}
                    View Items
                  </button>
                </div>
                <div className="flex items-center space-x-4">
                  <div>
                    <p className="text-sm text-gray-500 mt-2">
                      Placed on:{" "}
                      {new Date(
                        orderDetails.orderDate.seconds * 1000
                      ).toLocaleDateString("en-US")}
                    </p>
                    <p className="text-sm text-gray-500">
                      {`Total Amount: $ ${orderDetails.offerPriceTotal}`}
                    </p>

                    <p className="text-sm text-gray-500">
                      Payment Method: Cash on Delivery
                    </p>
                  </div>
                </div>
              </div>

              {orderDetails.order_status === "cancelled" ? (
                <div className="flex flex-col sm:flex-row justify-between items-center mt-12 space-y-6 sm:space-y-0 sm:space-x-4">
                  <div className="flex items-center w-full sm:w-auto">
                    <div
                      className={`w-10 h-10 rounded-full flex items-center justify-center ${
                        orderDetails && orderDetails.delivery_step >= 4
                          ? `bg-red-600`
                          : `bg-gray-200`
                      } text-white transition-transform hover:scale-105`}
                    >
                      {" "}
                      <i className="bi bi-x-circle text-red-500"></i>
                    </div>
                    <div className="ml-3">
                      <p className="text-base font-medium text-gray-700">
                        Cancelled
                      </p>
                      <p className="text-xs text-gray-500"></p>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div className="flex flex-col sm:flex-row justify-between items-center mt-12 space-y-6 sm:space-y-0 sm:space-x-4">
                    <div className="flex items-center w-full sm:w-auto">
                      <div
                        className={`w-10 h-10 rounded-full flex items-center justify-center ${
                          orderDetails && orderDetails.delivery_step >= 1
                            ? `bg-green-600`
                            : `bg-gray-300`
                        } text-white transition-transform hover:scale-105`}
                      >
                        <i className="bi bi-check"></i>
                      </div>
                      <div className="ml-3">
                        <p className="text-base font-medium text-gray-700">
                          Placed
                        </p>
                        <p className="text-xs text-gray-500">
                          {orderDetails && orderDetails.label}
                        </p>
                      </div>
                    </div>

                    <div
                      className={`flex-1 h-1 sm:w-auto ${
                        orderDetails && orderDetails.delivery_step >= 1
                          ? "bg-green-600"
                          : "bg-gray-300"
                      } rounded-full sm:mx-3`}
                    />

                    <div className="flex items-center w-full sm:w-auto">
                      <div
                        className={`w-10 h-10 rounded-full flex items-center justify-center ${
                          orderDetails && orderDetails.delivery_step >= 2
                            ? `bg-green-600`
                            : `bg-gray-300`
                        } text-white transition-transform hover:scale-105`}
                      >
                        <i className="bi bi-box"></i>
                      </div>
                      <div className="ml-3">
                        <p className="text-base font-medium text-gray-700">
                          Processed
                        </p>
                        <p className="text-xs text-gray-500">
                          {orderDetails.processed_date
                            ? new Date(
                                orderDetails.processed_date.seconds * 1000
                              ).toLocaleDateString("en-US")
                            : "N/A"}
                        </p>
                      </div>
                    </div>

                    <div
                      className={`flex-1 h-1 sm:w-auto ${
                        orderDetails && orderDetails.delivery_step >= 2
                          ? "bg-green-600"
                          : "bg-gray-300"
                      } rounded-full sm:mx-3`}
                    />

                    <div className="flex items-center w-full sm:w-auto">
                      <div
                        className={`w-10 h-10 rounded-full flex items-center justify-center ${
                          orderDetails && orderDetails.delivery_step >= 3
                            ? `bg-green-600`
                            : `bg-gray-300`
                        } text-white transition-transform hover:scale-105`}
                      >
                        {" "}
                        <i className="bi bi-truck"></i>
                      </div>
                      <div className="ml-3">
                        <p className="text-base font-medium text-gray-700 ">
                          Shipped
                        </p>
                        <p className="text-xs text-gray-500 ">
                          {orderDetails.shipped_date
                            ? new Date(
                                orderDetails.shipped_date.seconds * 1000
                              ).toLocaleDateString("en-US")
                            : "N/A"}
                        </p>
                      </div>
                    </div>

                    <div
                      className={`flex-1 h-1 sm:w-auto ${
                        orderDetails && orderDetails.delivery_step >= 3
                          ? "bg-green-600"
                          : "bg-gray-300"
                      } rounded-full sm:mx-3`}
                    />
                    <div className="flex items-center w-full sm:w-auto">
                      <div
                        className={`w-10 h-10 rounded-full flex items-center justify-center ${
                          orderDetails && orderDetails.delivery_step >= 4
                            ? `bg-green-600`
                            : `bg-gray-300`
                        } text-white transition-transform hover:scale-105`}
                      >
                        {" "}
                        <i className="bi bi-house"></i>
                      </div>
                      <div className="ml-3">
                        <p className="text-base font-medium text-gray-700">
                          Delivered
                        </p>
                        <p className="text-xs text-gray-500">
                          {orderDetails.delivered_date
                            ? new Date(
                                orderDetails.delivered_date.seconds * 1000
                              ).toLocaleDateString("en-US")
                            : "N/A"}
                        </p>
                      </div>
                    </div>

                    {/* added new step line bar */}

                    <div
                      className={`flex-1 h-1 sm:w-auto ${
                        orderDetails && orderDetails.delivery_step >= 3
                          ? "bg-green-600"
                          : "bg-gray-300"
                      } rounded-full sm:mx-3`}
                    />
                  </div>
                </>
              )}
            </div>
          ) : null}

          <div className="container mx-auto shadow py-6 px-6 max-w-7xl bg-white mt-8">
            {submitted ? (
              <p className="mt-6 text-green-600 ml-2 font-medium">
                Thank you for your feedback!{" "}
                <span
                  onClick={handleEditReview}
                  style={{ fontSize: "14px" }}
                  className="underline cursor-pointer hover:text-green-800 ml-4"
                >
                  Edit review
                </span>
              </p>
            ) : (
              <>
                <h1 className="text-2xl font-semibold mb-4">
                  Rate Your Experience
                </h1>

                <div className="flex  space-x-2 text-3xl text-yellow-500">
                  {[1, 2, 3, 4, 5].map((star) => (
                    <button
                      key={star}
                      onClick={() => handleRating(star)}
                      className={`transform hover:scale-110 text-lg transition-transform ${
                        rating >= star ? "text-yellow-500" : "text-gray-300"
                      }`}
                    >
                      ★
                    </button>
                  ))}
                </div>

                <p className="mt-4 text-gray-700">
                  {rating > 0
                    ? `You rated this ${rating} out of 5`
                    : "Click on a star to rate"}
                </p>

                <textarea
                  className="w-full mt-4 p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-800"
                  placeholder="Leave a comment about your experience..."
                  rows="4"
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                />
                <button
                  onClick={handleSubmit}
                  className="mt-4 px-6 py-2 bg-green-800 text-white font-medium rounded-lg hover:bg-green-800 transition-colors"
                >
                  {reviewLoading ? (
                    <div className="flex items-center justify-center">
                      <svg
                        className="animate-spin h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                        ></path>
                      </svg>
                      <span className="ml-2 text-white">Loading...</span>
                    </div>
                  ) : (
                    "Submit"
                  )}
                </button>
              </>
            )}
          </div>

          {isPopupOpen && (
            <div>
              <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 overflow-scroll z-50">
                <div className="bg-white rounded-lg shadow-lg p-6 w-full h-auto sm:w-1/2 mx-4 relative overflow-scroll px-6  max-h-[700px]">
                  <button
                    onClick={togglePopup}
                    className="absolute top-1 right-1 p-1 text-green-800 hover:text-red-800 transition"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-x-circle"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                      <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                    </svg>
                  </button>
                  {orderDetails &&
                    orderDetails.items.map((item, index) => (
                      <div
                        key={index}
                        className="md:flex border border-gray-400 border-dotted rounded-xl items-stretch py-4 md:py-2 lg:py-2 px-6 mb-4 overflow-auto "
                      >
                        {" "}
                        {/* Add margin bottom here */}
                        <div className="md:w-4/12 py-6 2xl:w-1/4 w-full">
                          <img
                            src={item.thumb_url}
                            alt="product"
                            className="w-auto h-24 mx-auto object-center object-cover"
                          />
                        </div>
                        <div className="md:pl-3 md:w-8/12 2xl:w-3/4 flex flex-col justify-center">
                          <p className="text-lg leading-3 text-gray-600 md:pt-0 md:mb-2 mt-2">
                            {item.product_name}
                          </p>
                          <div className="flex items-center justify-between w-full mt-2">
                            <p className="text-base font-medium text-gray-700">
                              Qty:{" "}
                              <span className="font-semibold">
                                {item.cart_quantity}
                              </span>
                            </p>
                            <p className="text-base font-medium text-gray-700">
                              Price:{" "}
                              <span className="font-semibold">
                                ${item.offer_price}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}
