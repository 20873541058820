import React, { useEffect, useRef, useState } from 'react';  
import './css/styles.css';  
import { useHomedata } from '../Admin/Homedata/HomedataProvider';
import { collection, getDocs, limit, orderBy, query, where } from 'firebase/firestore';
import { db } from '../Admin/Config/Config';
import { useNavigate } from 'react-router-dom';

export default function Slider() {   
  const homeData = useHomedata();
  const [categoryList, setCategoryList] = useState([]);
  const navigate = useNavigate();

  const fetchCateogories = async () => {
    const q = query(collection(db, "category"), where("status", "==", true), orderBy("order", "asc"), limit(20));
    const querySnapshot = await getDocs(q);
    const categories = [];
    querySnapshot.forEach((doc) => {
      categories.push({ id: doc.id, ...doc.data() });
    });
    setCategoryList(categories);
  };

  useEffect(() => {
    fetchCateogories();
  }, []);

  const sliderRef = useRef(null);

  const scrollRight = () => {     
    if (sliderRef.current) {       
      sliderRef.current.scrollBy({ left: sliderRef.current.offsetWidth, behavior: 'smooth' });     
    }   
  };

  const navigationPage = (category) => {
    navigate(`/search-items?query=${encodeURIComponent(category)}&f=${category}`);
  };

  return (
    <>
      {homeData && homeData.is_row1 ? (
  <div className="hidden sm:block relative mx-auto max-w-8xl  sm:px-12  bg-white rounded-xl text-center mt-2 py-4">
          <h2 className="text-left text-xl font-medium mb-8">
            {homeData.row1}
          </h2>
   
          <div className="overflow-x-auto hide-scrollbar" ref={sliderRef}>
            <div className="flex lg:flex-nowrap">
              {categoryList.map((category, index) => (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => { navigationPage(category.category_name) }}
                  key={index}
                  className="flex-shrink-0 w-24 md:w-32 lg:w-32 flex flex-col items-start mr-4 group transition-transform duration-300 hover:scale-60"
                >
                  <div className="p-3 border bg-gray-50 border-1 rounded-xl flex items-center justify-center w-full transition duration-300  hover:rounded-xl">
                    
                    <div className="shimmer rounded-md w-20 h-20 lg:w-20 lg:h-20">
                      <img
                        src={category.image}
                        alt={category.category_name}
                        className="h-20 w-20 lg:h-20 lg:w-20 rounded-md object-cover"
                        onLoad={(e) => e.target.parentNode.classList.remove('shimmer')}
                      />
                    </div>
                  </div>
                  <span className="text-xs text-left ml-2 text-gray-800 mt-2">
                    {category.category_name}
                  </span>
                </div>
              ))}
            </div>
          </div>

          <button
            onClick={scrollRight}
            className="absolute top-1/2 right-0 transform -translate-y-1/2 text-black p-3 shadow-md rounded-full transition duration-300"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="26"
              height="26"
              fill="currentColor"
              className="bi bi-arrow-right-short"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8"
              />
            </svg>
          </button>
        </div>
        
      ) : null}
    </>
  );
}
