// ToastContext.js
import React, { createContext, useContext, useCallback } from "react";
import { ToastContainer, toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ToastContext = createContext();

export const useToast = () => useContext(ToastContext);

export function ToastProvider({ children }) {
  const showSuccess = useCallback(
    (message = "Success! Operation completed.") => {
      toast.success(message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        transition: Zoom,
      });
    },
    []
  );

  const showError = useCallback((message = "Danger! Something went wrong.") => {
    toast.error(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      transition: Zoom,
    }
  );
  }, []);
    const showWarning = useCallback(
      (message = "Warning! Check your input.") => {
        toast.warn(message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          transition: Zoom,
        });
      },
      []
    );

  return (
    <ToastContext.Provider value={{ showSuccess, showError, showWarning }}>
      {children}
      <ToastContainer
        theme="light"
        position="top-right"
        autoClose={3000}
        hideProgressBar
        closeOnClick
        pauseOnHover
        draggable
        pauseOnFocusLoss
      />
    </ToastContext.Provider>
  );
}
