/* eslint-disable react-hooks/exhaustive-deps */
import { getAuth, signOut } from 'firebase/auth';
import React, { useEffect, useState,useRef } from 'react';
import { FaHistory, FaShippingFast, FaUserCog } from 'react-icons/fa';
import { app, db } from '../../Admin/Config/Config';
import { useLoginPopup } from '../../Auth/LoginContext';
import { collection, doc, getDocs, limit, orderBy, query, startAfter, updateDoc, where } from 'firebase/firestore';
import { useToast } from '../../Toast.jsx/Toast';
import { useNavigate } from 'react-router-dom';
import Profileempty from './Profileempty';

const ProfilePage = (user) => {
  const [activeSection, setActiveSection] = useState("Account Settings");
  const { openPopup } = useLoginPopup();
  const { showSuccess, showWarning } = useToast();
  const navigate = useNavigate();
  const auth = getAuth(app);

  useEffect(() => {
    if (!auth.currentUser) {
      openPopup();
    }
  }, [auth]);

  // =================== Edit Profile ================ //
  const [editFlag, setEditFlag] = useState(false);
  const [userInfo, setUserInfo] = useState({
    name: user.name,
    mobile: user.mobile,
  });
  const handleSaveProfile = async (e) => {
    e.preventDefault();
    const userref = doc(db, "users", auth.currentUser.uid);
    await updateDoc(userref, {
      name: userInfo.name,
      mobile: userInfo.mobile,
    });
    showSuccess("Profile updated successfully");
    setEditFlag(false);
  };

  // =================== Orders History ================ //
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
const fetchOrders = async (lastVisibleDoc = null) => {
  const datas = [];
  const ref = collection(db, "orders");
  let q = query(
    ref,
    where("uid", "==", auth.currentUser.uid),
    orderBy("orderDate", "desc"),
    limit(10)
  );
  if (lastVisibleDoc) {
    q = query(q, startAfter(lastVisibleDoc));
  }

  const docs = await getDocs(q);

  if (docs.docs.length === 0) {
    return { datas: [], lastVisible: null }; // No more documents
  }

  docs.forEach((doc) => {
    datas.push({
      id: doc.id,
      ...doc.data(),
      label:
        doc.data().order_status === "pending"
          ? "Order placed"
          : capitalizeFirstLetter(doc.data().order_status),
    });
  });

  const lastVisible = docs.docs[docs.docs.length - 1];
  return { datas, lastVisible };
};


  const [orders, setOrders] = useState([]);
  const [lastVisibleDoc, setLastVisibleDoc] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true); // Track if there are more orders to load

  const loadMoreOrders = async () => {
    if (!auth.currentUser || !hasMore || loading) return; // Prevent further calls if no more orders
    setLoading(true);

    const { datas, lastVisible } = await fetchOrders(lastVisibleDoc);

    if (datas.length === 0) {
      setHasMore(false); // No more orders to load
    } else {
      setOrders((prevOrders) => [...prevOrders, ...datas]);
      setLastVisibleDoc(lastVisible);
    }

    setLoading(false);
  };
  useEffect(() => {
    const loadInitialOrders = async () => {
      const { datas, lastVisible } = await fetchOrders();
      setOrders(datas);
      setLastVisibleDoc(lastVisible);
    };
    if (auth.currentUser) {
      loadInitialOrders();
    }
  }, []);

  // ================== Manage Adrress ================ //
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSaveClick = async () => {
    const hasEmptyField = Object.values(address).some(
      (value) => value === "" || value === null || value === undefined
    );
    if (hasEmptyField) {
      showWarning("Please fill all the fields");
    } else {
      await updateDoc(
        doc(db, "users", auth.currentUser.uid, "address", address.id),
        {
          ...address,
        }
      );
      showSuccess("Address updated successfully");
      await fetchAddress();
      setIsModalOpen(false);
    }
  };
  const handleOpenModal = (address) => {
    setIsModalOpen(true);
    setchangeAddress(address.country);
    setaddress(address);
  };
  const [addresses, setAddresses] = useState([]);
  const [address, setaddress] = useState({});

  const fetchAddress = async () => {
    setAddresses([]);
    const ref = collection(db, "users", auth.currentUser.uid, "address");
    const querySnapshot = await getDocs(ref);
    const add = [];
    querySnapshot.forEach((doc) => {
      add.push({ id: doc.id, ...doc.data() });
    });
    setAddresses(add);
  };
  useEffect(() => {
    if (auth.currentUser) {
      fetchAddress();
    }
  }, []);
  // =============================== Fetch Countries ================================ //
  const handleCountryChange = (e) => {
    const countryName = e.target.value;
    setaddress({ ...address, country: countryName });
  };
  const setchangeAddress = (value) => {
    const countryName = value;
    setaddress({ ...address, country: countryName });
  };

  // ============================= Format Address ================================ //
  const formatAddress = (address) => {
    const { name, street, city, state, zip, country } = address;
    return `${name ? name + ", " : ""}${street ? street + ", " : ""}${
      city ? city + ", " : ""
    }${state ? state + ", " : ""}${zip ? zip + ", " : ""}${
      country ? country : ""
    }`.trim();
  };

  const mainContentRef = useRef(null);

  const handleSectionClick = (sectionName) => {
    setActiveSection(sectionName);

    if (window.innerWidth <= 768 && mainContentRef && mainContentRef.current) {
      mainContentRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const renderSectionContent = () => {
    switch (activeSection) {
      case "Order History":
        return (
          <div className="space-y-6">
            {orders.map((order) => (
              <div className="p-6 rounded-lg bg-slate-50">
                <div className="flex justify-between items-center">
                  <div>
                    <p className="font-semibold text-gray-800">
                      Order #{order.order_id}
                    </p>
                    <p className="text-gray-500">Items: {order.items.length}</p>

                    <p className="text-gray-500">
                      Placed on:{" "}
                      {new Date(
                        order.orderDate.seconds * 1000
                      ).toLocaleDateString("en-US")}
                    </p>
                    <p className="text-gray-500">Status : {order.label}</p>
                  </div>
                  <div className="text-right">
                    <p className="text-lg font-semibold text-green-900">
                      ${order.offerPriceTotal}
                    </p>
                    <div
                      onClick={() =>
                        navigate("/orderhistory", {
                          state: {
                            order: { id: order.id },
                          },
                        })
                      }
                      className="mt-2 text-sm font-semibold text-white px-4 text-center py-2 bg-green-500 rounded-lg hover:bg-green-600 transition-all duration-300 cursor-pointer  ring-2 ring-green-200 hover:ring-green-500 "
                    >
                      View Details
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {orders && orders.length ? (
              <div className="flex justify-center items-center">
                <button
                  className="px-4 bg-gray-200 text-gray-900 rounded  py-2"
                  onClick={loadMoreOrders}
                  disabled={loading&&!hasMore}
                >
                  {loading
                    ? "Loading..."
                    : hasMore
                    ? "Load More"
                    : "No More Orders"}
                </button>
              </div>
            ) : (
              <p>No orders yet...</p>
            )}
          </div>
        );
      case "Shipping Addresses":
        return (
          <div className="space-y-6">
            {addresses &&
              addresses.map((address, index) => (
                <div className="p-6 rounded-lg border border-gray-200 flex justify-between items-center">
                  <div>
                    <p className="font-semibold text-gray-800">
                      Address #{index + 1}
                    </p>
                    <p className="text-gray-500">{formatAddress(address)}</p>
                  </div>
                  <button
                    onClick={() => handleOpenModal(address)}
                    className="text-green-800 hover:underline"
                  >
                    Edit
                  </button>
                </div>
              ))}
          </div>
        );
      case "Account Settings":
        return (
          <div className="space-y-6">
            {editFlag ? (
              <form
                onSubmit={handleSaveProfile}
                className="space-y-4 p-6 border border-gray-200 rounded-lg bg-white "
              >
                <div>
                  <label
                    className="block text-sm font-medium text-gray-700"
                    htmlFor="name"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    value={userInfo.name}
                    onChange={(e) =>
                      setUserInfo({ ...userInfo, name: e.target.value })
                    }
                    placeholder="Enter your name"
                    required
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-green-500"
                  />
                </div>
                <div>
                  <label
                    className="block text-sm font-medium text-gray-700"
                    htmlFor="phone"
                  >
                    Phone
                  </label>
                  <input
                    type="tel"
                    id="phone"
                    value={userInfo.mobile}
                    onChange={(e) =>
                      setUserInfo({ ...userInfo, mobile: e.target.value })
                    }
                    required
                    placeholder="Enter your phone number"
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-green-500"
                  />
                </div>
                <button
                  type="submit"
                  className="mt-4 w-full px-4 py-2 text-white bg-green-800 hover:bg-green-700 rounded transition-all duration-200"
                >
                  Submit
                </button>
              </form>
            ) : (
              <div className="p-6 border border-gray-200 rounded-lg flex justify-between items-start">
                <div className="flex-grow">
                  <div className="flex justify-between items-center mb-4">
                    <h4 className="font-semibold text-gray-800 text-lg">
                      Personal Information
                    </h4>
                    <button
                      onClick={() => setEditFlag(true)}
                      className="px-4 py-2 text-white bg-green-800 hover:bg-green-700 rounded transition-all duration-200"
                    >
                      Edit
                    </button>
                  </div>

                  <p className="text-gray-700 mb-2">
                    Name: <span className="font-medium">{userInfo.name}</span>
                  </p>
                  <p className="text-gray-700 mb-2">
                    Email:{" "}
                    <span className="font-medium">
                      {getAuth().currentUser?.email}
                    </span>
                  </p>
                  <p className="text-gray-700 mb-2">
                    Phone:{" "}
                    <span className="font-medium">{userInfo.mobile}</span>
                  </p>
                </div>
              </div>
            )}
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <>
      {getAuth().currentUser ? (
        <div className="min-h-screen bg-gradient-to-br from-gray-100 to-blue-50">
          <div className="py-12">
            <div className="container mx-auto max-w-8xl flex items-center justify-between px-4 py md:px-12">
              <div className="flex items-center space-x-6">
                <img
                  src={
                    getAuth().currentUser && getAuth().currentUser.photoURL
                      ? getAuth().currentUser.photoURL
                      : "https://uxwing.com/wp-content/themes/uxwing/download/peoples-avatars/default-avatar-profile-picture-male-icon.png"
                  }
                  alt="Profile"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src =
                      "https://uxwing.com/wp-content/themes/uxwing/download/peoples-avatars/default-avatar-profile-picture-male-icon.png";
                  }}
                  className="w-16 h-16 rounded-full border-2 border-zinc-200 shadow-lg"
                />
                <div>
                  <h2 className="text-lg font-bold text-gray-900">
                    {user?.name}
                  </h2>
                  <p className="text-gray-700 text-xs">
                    {auth.currentUser?.email}
                  </p>
                </div>
              </div>

              <div>
                {isModalOpen && (
                  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white rounded-lg shadow-lg w-1/3 p-6">
                      <h2 className="text-lg font-bold text-gray-900 mb-4">
                        Edit Address
                      </h2>

                      <div className="mb-4">
                        <label className="block text-gray-700">
                          Full Name <span className="text-red-500">{`*`}</span>
                        </label>
                        <input
                          type="text"
                          value={address.name}
                          onChange={(e) =>
                            setaddress({ ...address, name: e.target.value })
                          }
                          className="border border-gray-300 rounded-lg px-4 py-2 mt-1 w-full"
                        />
                      </div>
                      <div className="mb-4">
                        <label className="block text-gray-700">
                          Contact No <span className="text-red-500">{`*`}</span>
                        </label>
                        <input
                          type="text"
                          value={address.mobile}
                          onChange={(e) =>
                            setaddress({ ...address, mobile: e.target.value })
                          }
                          className="border border-gray-300 rounded-lg px-4 py-2 mt-1 w-full"
                        />
                      </div>

                      <div className="mb-4">
                        <label className="block text-gray-700">City</label>
                        <input
                          type="text"
                          value={address.city}
                          onChange={(e) =>
                            setaddress({ ...address, city: e.target.value })
                          }
                          className="border border-gray-300 rounded-lg px-4 py-2 mt-1 w-full"
                        />
                      </div>
                      <div className="mb-4">
                        <label className="block text-gray-700">
                          Unit Number{" "}
                        </label>
                        <input
                          type="text"
                          value={address.unit_number}
                          onChange={(e) =>
                            setaddress({
                              ...address,
                              unit_number: e.target.value,
                            })
                          }
                          className="border border-gray-300 rounded-lg px-4 py-2 mt-1 w-full"
                        />
                      </div>

                      <div className="mb-4">
                        <label className="block text-gray-700">
                          Country <span className="text-red-500">{`*`}</span>
                        </label>
                        <select
                          value={address.country}
                          onChange={handleCountryChange}
                          required
                          className="px-4 py-3 bg-white text-gray-800 w-full text-sm border-2 rounded-md focus:border-green-800 outline-none appearance-none"
                        >
                          <option value={`Canada`}>{`Canada`}</option>
                        </select>
                      </div>
                      <div className="mb-4">
                        <label className="block text-gray-700">
                          State<span className="text-red-500">{`*`}</span>
                        </label>
                        <select
                          value={address.state}
                          required
                          onChange={(e) =>
                            setaddress({ ...address, state: e.target.value })
                          }
                          className="px-4 py-3 bg-white text-gray-800 w-full text-sm border-2 rounded-md focus:border-green-800 outline-none appearance-none"
                        >
                          <option key={address.id} value={`Ontario`}>
                            {`Ontario`}
                          </option>
                        </select>
                      </div>

                      <div className="mb-4">
                        <label className="block text-gray-700">
                          Zip Code<span className="text-red-500">{`*`}</span>
                        </label>
                        <input
                          type="text"
                          value={address.zip}
                          onChange={(e) =>
                            setaddress({ ...address, zip: e.target.value })
                          }
                          className="border border-gray-300 rounded-lg px-4 py-2 mt-1 w-full"
                        />
                      </div>

                      <div className="flex justify-end space-x-4">
                        <button
                          onClick={handleCloseModal}
                          className="px-4 py-2 bg-gray-400 text-white rounded-lg hover:bg-gray-500"
                        >
                          Cancel
                        </button>
                        <button
                          onClick={handleSaveClick}
                          className="px-4 py-2 bg-green-800 text-white rounded-lg hover:bg-green-900"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="container mx-auto max-w-8xl px-4 md:px-12">
            <div className="flex flex-col md:flex-row space-y-6 md:space-y-0 md:space-x-8">
              {/* Sidebar */}
              <div className="w-full md:w-1/3 shadow-lg rounded-lg p-6 max-h-[530px] overflow-auto bg-white">
                <h3 className="text-xl font-bold mb-6 text-gray-800">
                  Account Settings
                </h3>
                <ul className="space-y-6">
                  <li className="flex items-center space-x-3">
                    <FaUserCog className="text-green-800" />
                    <h1
                      onClick={() => handleSectionClick("Account Settings")}
                      className={`text-gray-600 cursor-pointer transition ${
                        activeSection === "Account Settings"
                          ? "hover:text-gray-800"
                          : "hover:text-gray-600"
                      }`}
                    >
                      Account Settings
                    </h1>
                  </li>
                  <li className="flex items-center space-x-3">
                    <FaHistory className="text-green-800" />
                    <h1
                      onClick={() => handleSectionClick("Order History")}
                      className={`text-gray-600 cursor-pointer transition ${
                        activeSection === "Order History"
                          ? "hover:text-gray-800"
                          : "hover:text-gray-600"
                      }`}
                    >
                      Order History
                    </h1>
                  </li>
                  <li className="flex items-center space-x-3">
                    <FaShippingFast className="text-green-800" />
                    <h1
                      onClick={() => handleSectionClick("Shipping Addresses")}
                      className={`text-gray-600 cursor-pointer transition ${
                        activeSection === "Shipping Addresses"
                          ? "hover:text-gray-800"
                          : "hover:text-gray-600"
                      }`}
                    >
                      Shipping Addresses
                    </h1>
                  </li>
                  {getAuth().currentUser ? (
                    <li className="flex items-center space-x-3">
                      <FaUserCog className="text-green-800" />
                      <h1
                        onClick={() => {
                          signOut(getAuth()).then(() => {
                            window.location.href = "/";
                          });
                        }}
                        className="text-gray-600 cursor-pointer transition hover:text-gray-800"
                      >
                        Logout
                      </h1>
                    </li>
                  ) : null}
                </ul>
              </div>

              <div
                className="w-full md:w-2/3 max-h-[530px] overflow-auto bg-white shadow-lg rounded-lg p-8"
                ref={mainContentRef}
              >
                <h3 className="text-xl font-bold mb-6 text-gray-800">
                  {activeSection}
                </h3>
                {renderSectionContent()}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Profileempty />
      )}
    </>
  );
};

export default ProfilePage;
