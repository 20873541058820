import React, { useState, useEffect } from "react";
import {  SortBy, useHits, useRange } from "react-instantsearch";
import * as Slider from "@radix-ui/react-slider";
import "./slider.css";

export function RangeSlider({ attribute }) {
  const { start, range, canRefine, refine } = useRange({ attribute });
  const { min, max } = range;
    const { results } = useHits();
    const nbHits = results ? results.nbHits : 0;
  const from = Math.max(min, Number.isFinite(start[0]) ? start[0] : min);
  const to = Math.min(max, Number.isFinite(start[1]) ? start[1] : max);
  const [value, setValue] = useState([from, to]);

  useEffect(() => {
    setValue([from, to]);
  }, [from, to]);

  const handleValueCommit = (newValue) => {
    refine(newValue);
  };

  return (
    <>
      {nbHits > 0 ? (
        <>
          <div className="flex justify-between py-4">
            <h1 className="text-sm font-semibold mr-2">By price</h1>
            <SortBy
              items={[
                { label: "None", value: "products" },
                { label: "Price (asc)", value: "products_price_asc" },
                { label: "Price (desc)", value: "products_price_desc" },
              ]}
              className="sort-by-dropdown text-sm focus:outline-none focus:ring-2 focus:ring-green-500"
            />
          </div>
            <div className="filter-section mb-4">
                  <div className="flex items-center justify-between w-full border-b border-gray-200 mb-5"/>
                
                  <div className="mb-4 filter-content py-2 ">
          <div className="flex items-center justify-between mb-2">
            <div className="flex items-center">
              <div className="text-xs font-semibold py-2">Filter by price</div>
            </div>
          </div>
          <div className="slider-values">
            <span>{`From: $${value[0]}`}</span>
            <span>{`To: $${value[1]}`}</span>
          </div>
          <div className="py-4">
            <Slider.Root
              min={min}
              max={max}
              value={value}
              onValueChange={setValue}
              onValueCommit={handleValueCommit}
              disabled={!canRefine}
              className="slider-root"
            >
              <Slider.Track className="slider-track">
                <Slider.Range className="slider-range" />
              </Slider.Track>
              <Slider.Thumb className="slider-thumb" />
              <Slider.Thumb className="slider-thumb" />
            </Slider.Root>
          </div>
          </div>
        </div>
        </>
      ) : null}
    </>
  );
}
