import React, { useState, useEffect } from 'react';
import img from '../Home/Footer/Mallu Bazzar Logo_ F_White.png';

export default function ComingSoon() {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const calculateTimeLeft = () => {
    const targetDate = new Date('2024-11-26T00:00:00'); // Set your target date
    const currentTime = new Date();
    const difference = targetDate - currentTime;

    if (difference > 0) {
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((difference / (1000 * 60)) % 60);
      const seconds = Math.floor((difference / 1000) % 60);

      setTimeLeft({ days, hours, minutes, seconds });
    }
  };

  useEffect(() => {
    const timer = setInterval(calculateTimeLeft, 1000);
    return () => clearInterval(timer); // Cleanup interval on component unmount
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-b from-[#004d40] via-[#00695c] to-[#00796b] flex items-center justify-center relative overflow-hidden">
      <div className="absolute inset-0 bg-gradient-to-r from-green-800 via-transparent to-green-900 opacity-60"></div>
      <div className="absolute top-0 left-0 w-96 h-96 bg-gradient-to-br from-green-500 to-green-700 rounded-full blur-3xl opacity-30 animate-pulse"></div>
      <div className="absolute bottom-0 right-0 w-96 h-96 bg-gradient-to-br from-green-500 to-green-700 rounded-full blur-3xl opacity-30 animate-pulse delay-200"></div>

      <div className="text-center z-10 w-full max-w-lg px-4">
        <div className="mb-8">
          <img src={img} alt="Logo" className="mx-auto w-40" />
        </div>

        <h1 className="text-5xl font-bold text-white mb-4">
          എടാ മോനെ...! <span className="text-yellow-500">ഇതാ എത്തി</span>
        </h1>

        <p className="text-gray-200 text-lg mt-6">
          We are cooking up something amazing for you. Stay tuned! <br />
        </p>

        <div className="flex justify-center items-center space-x-6 text-white text-2xl mt-12">
          {Object.entries(timeLeft).map(([unit, value]) => (
            <div key={unit} className="text-center">
              <span className="block font-extrabold text-4xl">{value}</span>
              <span className="text-sm text-gray-300 capitalize">{unit}</span>
            </div>
          ))}
        </div>

        <div className="mt-6">
          <div className="text-gray-200 mb-6 p-4 rounded-lg">
            <p className="mb-2">
              WhatsApp:{" "}
              <a
                href="tel:+12268984298"
                className="text-blue-400 hover:underline"
              >
                +12268984298
              </a>
            </p>

            <p className="text-sm mt-2 text-gray-100">
              We strive to respond to all social media inquiries within 30
              minutes.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
