import React, { createContext, useContext, useState } from "react";
import {
  doc,
  setDoc,
  getDoc,
  updateDoc,
  arrayUnion,
  onSnapshot,
  // arrayRemove,
} from "firebase/firestore";
import {  auth, db } from "../../Admin/Config/Config";
import { toast, Zoom } from "react-toastify";

const CartContext = createContext();

export const useCartContext = () => {
  return useContext(CartContext);
};

const CartProvider = ({ children }) => {
  const [cart, setCart] = useState([]);
  const [emptyCart, setemptyCart] = useState(false);

  // Adds only the product ID and quantity to the user's cart
  const addToCart = async (userId, product) => {
    if (auth.currentUser) {
      try {
        const cartRef = doc(db, "cart", userId);
        const cartSnap = await getDoc(cartRef);

        // Check if product already exists in the cart array
        const existingProduct = cart.find(
          (item) => item.productId === product.id
        );
        if (existingProduct) {
          toast.warn("Product already exists in cart", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            transition: Zoom,
          });
          return;
        }

        // Add the new product to the cart array
        if (cartSnap.exists()) {
          await updateDoc(cartRef, {
            items: arrayUnion({ productId: product.id, cart_quantity: 1 }),
          });
        } else {
          await setDoc(cartRef, {
            items: [{ productId: product.id, cart_quantity: 1 }],
          });
        }

        toast.success("Item added to cart", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          transition: Zoom,
        });
      } catch (error) {
        console.error("Error adding product to cart:", error);
      }
    } else {
      // ======================================== Local Storage Cart ======================================== //
      const localCart = JSON.parse(localStorage.getItem("cart")) || [];
      const existingProduct = localCart.find(
        (item) => item.productId === product.id
      );

      if (existingProduct) {
        toast.warn("Product already exists in cart", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          transition: Zoom,
        });
        return;
      }

      // Add the new product to the local cart array
      localCart.push({ productId: product.id, cart_quantity: 1 });
      localStorage.setItem("cart", JSON.stringify(localCart));
       const event = new Event("cartUpdated");
       window.dispatchEvent(event);
      toast.success("Item added to cart", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        transition: Zoom,
      });
    }
  };

  // Fetches cart details

const fetchCart = async (userId) => {
  if(auth.currentUser){
  const cartRef = doc(db, "cart", userId);
  const unsubscribeCart = onSnapshot(cartRef, (cartSnap) => {
    if (cartSnap.exists()) {
      const cartItems = cartSnap.data().items;
      if(cartItems.length===0){
        setCart([]);
        setemptyCart(true);
        return cart;
      } else if(cartItems?.length<cart?.length){
                setemptyCart(false);
        // Extract product IDs from Firestore cart items
        const firestoreProductIds = cartItems.map((item) => item.productId);
        // Filter local cart items that are not in Firestore
          const updatedCart = cart.filter((localItem) =>
            firestoreProductIds.includes(localItem.productId)
          );
        setCart(updatedCart);
        return cart;
      } else {
        setemptyCart(false);
      }
       
      const unsubscribeProducts = cartItems.map((item) => {
        const productRef = doc(db, "products", item.productId);
        return onSnapshot(productRef, (productSnap) => {
          if (productSnap.exists()) {
            const updatedProduct = {
              ...productSnap.data(),
              cart_quantity: item.cart_quantity,
              productId: item.productId,
            };
            setCart((prevCart) => {
              const updatedCart = prevCart.map((prod) =>
                prod.productId === updatedProduct.productId
                  ? updatedProduct
                  : prod
              );

              if (
                !updatedCart.some(
                  (prod) => prod.productId === updatedProduct.productId
                )
              ) {
                updatedCart.push(updatedProduct);
              }

              return updatedCart;
            });
          } else {
                  const updatedItems = cart.filter(
                    (data) => data.productId === item.productId
                  );
                  setCart(updatedItems);

          }
        });
      });

      return () => {
        unsubscribeCart();
        unsubscribeProducts.forEach((unsubscribe) => unsubscribe());
      };
    } else {
      setCart([]);
      return null;
    }
  });
} else {
    // User is not authenticated, fetch cart from localStorage
    const localCart = JSON.parse(localStorage.getItem("cart")) || [];
    
    if (localCart.length === 0) {
      setCart([]);
      setemptyCart(true);
      return;
    }

    // Extract all product IDs from the local cart
    const productIds = localCart.map(item => item.productId);

    try {
      // Fetch product details for each productId in localCart
      const productPromises = productIds.map(productId => {
        const productRef = doc(db, "products", productId);
        return getDoc(productRef);
      });
      
      const productSnapshots = await Promise.all(productPromises);

      // Map over snapshots to build the updated cart with product details
      const updatedCart = productSnapshots
        .filter((productSnap) => productSnap.exists())
        .map((productSnap) => {
          const productData = productSnap.data();
          const localCartItem = localCart.find(item => item.productId === productSnap.id);
          return {
            ...productData,
            cart_quantity: localCartItem.cart_quantity,
            productId: productSnap.id,
          };
        });

      setCart(updatedCart);
      setemptyCart(false);
    } catch (error) {
      console.error("Error fetching products from Firestore:", error);
      setCart([]);
      setemptyCart(true);
    }
  }
}


  // Updates only the quantity of a product in the cart
 const updateQuantity = async (userId, productId, quantity) => {
  if(auth.currentUser){
   try {
     const cartRef = doc(db, "cart", userId);
     const cartSnap = await getDoc(cartRef);
     const items = cartSnap.data().items;
     const productRef = doc(db, "products", productId);
     const productSnap = await getDoc(productRef);
     if(productSnap.exists()){
     const product = productSnap.data();
    if (product.stock>=quantity){
     const updatedItems = items.map((item) =>
       item.productId === productId
         ? { ...item, cart_quantity: quantity }
         : item
     );

     await setDoc(cartRef, { items: updatedItems });
    //  const updatedCart = await fetchCart(auth.currentUser.uid);
    //  setCart(updatedCart);

     return "success";
    } else {
      toast.warn("Out of stock", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        transition: Zoom,
      });
    }
  } else {
     toast.error("Something went wrong... Please try again", {
       position: "top-right",
       autoClose: 3000,
       hideProgressBar: true,
       closeOnClick: true,
       pauseOnHover: true,
       draggable: true,
       transition: Zoom,
     });
  }
   } catch (error) {
     console.error("Error updating product quantity:", error);
   }
  } else {
    const localCart = JSON.parse(localStorage.getItem("cart")) || [];
    const productIndex = localCart.findIndex((item) => item.productId === productId);

    if (productIndex > -1) {
      localCart[productIndex].cart_quantity = quantity;
    } else {
      localCart.push({ productId, cart_quantity: quantity });
    }
    localStorage.setItem("cart", JSON.stringify(localCart));
    fetchCart("");
    toast.success("Quantity updated", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      transition: Zoom,
    });
    
    return "success";
  }
 };


  // Removes a product from the cart by product ID
  const removeFromCart = async (userId, productId) => {
    if(auth.currentUser){
      try {
        const cartRef = doc(db, "cart", userId);
        const cartSnap = await getDoc(cartRef);
        const items = cartSnap.data().items;

        const updatedItems = items.filter(
          (item) => item.productId !== productId
        );
        await setDoc(cartRef, { items: updatedItems }, { merge: true });
        const updatedCart = await fetchCart(auth.currentUser.uid);
        setCart(updatedCart);
        return true;
      } catch (error) {
        console.error("Error removing product from cart:", error);
      }
    } else {
      const localCart = JSON.parse(localStorage.getItem("cart")) || [];
      const updatedCart = localCart.filter((item) => item.productId !== productId);
      localStorage.setItem("cart", JSON.stringify(updatedCart));
      fetchCart("");
      return true;
    }
  };

  return (
    <CartContext.Provider
      value={{
        cart,
        addToCart,
        fetchCart,
        updateQuantity,
        removeFromCart,
        emptyCart,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export default CartProvider;
