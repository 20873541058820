import React, {  useState } from "react";
import { createUserWithEmailAndPassword, sendPasswordResetEmail, signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
import { auth, db, googleProvider } from '../Admin/Config/Config';
import { useLoginPopup } from './LoginContext';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import './Login.css'
import img from './Mallu Bazzar Logo_F.png'
import { useToast } from "../Toast.jsx/Toast";
export default function Login() {
  const { isOpen, closePopup } = useLoginPopup();
  const [signUPFlag, setSignUPFlag] = useState(false);
  const [loading, setLoading] = useState(false);
  const {showError, showSuccess} = useToast();
  const handleGoogleLogin = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider)
      closePopup()
      await syncCartOnLogin(result.user.uid);
    } catch (error) {
      console.error("Google login failed: ", error);
    }
  };
const syncCartOnLogin = async (userId) => {
  try {
    const cartRef = doc(db, "cart", userId);
    const cartSnap = await getDoc(cartRef);

    // Fetch local cart items from localStorage
    const localCart = JSON.parse(localStorage.getItem("cart")) || [];

    if (cartSnap.exists()) {
      const firestoreCartItems = cartSnap.data().items;

      const mergedCart = [...firestoreCartItems];

      localCart.forEach((localItem) => {
        const existingItemIndex = mergedCart.findIndex(
          (item) => item.productId === localItem.productId
        );

        if (existingItemIndex > -1) {
          mergedCart[existingItemIndex].cart_quantity +=
            localItem.cart_quantity;
        } else {
          mergedCart.push(localItem);
        }
      });

      await setDoc(cartRef, { items: mergedCart, test: true }, { merge: true });
    } else {
      await setDoc(cartRef, { items: localCart, test: true }, { merge: true });
    }
    localStorage.removeItem("cart");

    console.log("Cart synced successfully.");
  } catch (error) {
    console.error("Error syncing cart on login:", error);
  }
};
const [signupData, setSignupData] = useState({
  email: "",
  password: "",
  name: "",
  mobile: "",
});


const getErrorMessage = (errorCode) => {
  const errorMessages = {
    "auth/email-already-in-use":
      "This email is already in use. Please use a different email.",
    "auth/invalid-email":
      "The email address is invalid. Please enter a valid email.",
    "auth/weak-password":
      "The password is too weak. Please use a stronger password.",
    "auth/operation-not-allowed":
      "Email/password sign-up is currently disabled. Please try again later.",
    "auth/network-request-failed":
      "Network error. Please check your internet connection and try again.",
      "auth/invalid-login-credentials":
      "Invalid login credentials. Please check your email and password and try again.",
    // Add more error codes as needed
    default: "An unexpected error occurred. Please try again.",
  };

  return errorMessages[errorCode] || errorMessages.default;
};
const handleSignUp = (e) => {
  e.preventDefault();
  setLoading(true);
  const { email, password } = signupData;
 createUserWithEmailAndPassword(auth, email, password)
   .then(async(userCredential) => {
     const user = userCredential.user;
     await setDoc(
       doc(db, "users", user.uid),
       {
         uid: user.uid,
         name: signupData.name,
         email: signupData.email,
         mobile: signupData.mobile,
         createdAt: new Date(),
         photoURL:
           "https://uxwing.com/wp-content/themes/uxwing/download/peoples-avatars/default-avatar-profile-picture-male-icon.png",
       },
       { merge: true }
     );
     await syncCartOnLogin(user.uid);
      setLoading(false);
      closePopup();
   })
   .catch((error) => {
    const errorCode = error.code;
     showError(getErrorMessage(errorCode));
     setLoading(false);
   });
};

 const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [email_s,setemail_s] = useState("");
  const [password_s,setpassword_s] = useState("");

  const handleEmailSiginIn = async (e) => {
    e.preventDefault();
    setLoading(true);
    signInWithEmailAndPassword(auth, email_s, password_s)
    .then(async(userCredential) => {
      const user = userCredential.user;
      await syncCartOnLogin(user.uid);
      setLoading(false);
      closePopup();
    })
    .catch((error) => {
      const errorCode = error.code;
      showError(getErrorMessage(errorCode));
      setLoading(false);
    });
  };
function isValidEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}
  const handleForgotPassword = () => {
  if (isValidEmail(email_s)) {
    sendPasswordResetEmail(auth, email_s)
      .then(() => {
       showSuccess("Password reset email sent successfully. Please check your inbox.");
      })
      .catch((error) => {
        showError("Failed to send password reset email. Please try again.");
      });
  } else {
    alert("Please enter a valid email address.");
  }
  }

  return (
    <>
      {isOpen && !signUPFlag ? (
        <div className="h-screen w-screen bg-white ">
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/70 backdrop-blur-sm">
            <div className="relative container m-auto px-6">
             <div className="m-auto w-full sm:w-10/12 md:w-6/12 lg:w-4/12">
                <div className="rounded-xl bg-white shadow-xl relative">
                  <button
                    onClick={() => {
                      setSignUPFlag(false);
                      closePopup();
                    }}
                    className="absolute top-4 right-4 text-gray-600 hover:text-gray-900 transition duration-300 text-xl"
                    aria-label="Close"
                  >
                    &times;
                  </button>
                  <div className="p-8">
                    <div className="space-y-2 text-center">
                      <img
                        src={img}
                        loading="lazy"
                        className="w-16 h-16 object-contain mx-auto"
                        alt="Logo"
                      />
                      <form onSubmit={handleEmailSiginIn}>
                        <div className=" grid space-y-3">
                          <label className="text-sm text-gray-800 flex items-center ml-1 ">
                            Email
                          </label>
                          <input
                            type="email"
                            onChange={(e) => setemail_s(e.target.value)}
                            required
                            placeholder="Email"
                            className="px-4 py-3 bg-white text-gray-800 w-full text-sm border-2 rounded-lg border-gray-300 focus:border-gray-300 outline-none"
                          />
                          <div>
                            <label className="text-sm text-gray-800 flex items-center ml-1 ">
                              Password
                            </label>
                            <div className="relative mt-2">
                              <input
                                onChange={(e) => setpassword_s(e.target.value)}
                                type={showPassword ? "text" : "password"}
                                id="password"
                                placeholder="Enter your password"
                                className="px-4 py-3 bg-white text-gray-800 w-full text-sm border-2 rounded-lg border-gray-200 focus:border-gray-300 outline-none pr-10"
                                required
                              />
                              <button
                                type="button"
                                className="absolute inset-y-0 right-3 flex items-center text-gray-500"
                                onClick={() => setShowPassword(!showPassword)}
                              >
                                {showPassword ? "🙈" : "👁️"}
                              </button>
                            </div>
                          </div>
                          <p onClick={handleForgotPassword} className="text-xs cursor-pointer font-medium text-gray-500 text-right underline">Forgot password ?</p>
                        </div>
                        <div className="py-4">
                          <button  type="submit" className="bg-green-800 py-3 w-full text-white rounded-xl hover:bg-green-700 transition duration-300">
                            {loading ? (
                              <div className="flex items-center justify-center">
                                <svg
                                  className="animate-spin h-5 w-5 text-white"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                >
                                  <circle
                                    className="opacity-25"
                                    cx="12"
                                    cy="12"
                                    r="10"
                                    stroke="currentColor"
                                    strokeWidth="4"
                                  ></circle>
                                  <path
                                    className="opacity-75"
                                    fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                  ></path>
                                </svg>
                                <span className="ml-2 text-white">
                                  Loading...
                                </span>
                              </div>
                            ) : (
                              "Login"
                            )}
                          </button>
                        </div>
                      </form>

                      <p className="text-xs">
                        You dont Have an account ?
                        <span
                          onClick={() => setSignUPFlag(true)}
                          className="ml-1 text-green-700 cursor-pointer"
                        >
                          Sign Up
                        </span>
                      </p>
                      <div className="flex items-center py-3">
                        <hr className="flex-grow border-gray-300" />
                        <h2 className="px-2 text-xs text-gray-900 font-bold">
                          Or
                        </h2>
                        <hr className="flex-grow border-gray-300" />
                      </div>
                    </div>
                    <div>
                      <div className="mt-4 grid space-y-4">
                        <button
                          onClick={handleGoogleLogin}
                          className="group h-12 px-6 border-2 border-gray-300 rounded-full transition duration-300 hover:border-green-900 focus:bg-green-50 active:bg-green-100"
                        >
                          <div className="relative flex items-center space-x-4 justify-center">
                            <img
                              src="https://img.icons8.com/color/48/google-logo.png"
                              className="absolute left-0 w-5"
                              alt="Google logo"
                            />
                            <span className="block w-max font-semibold tracking-wide text-gray-700 text-sm transition duration-300 group-hover:text-green-900 sm:text-base">
                              Continue with Google
                            </span>
                          </div>
                        </button>
                      </div>
                      <div className="mt-4 space-y-4 py-2 text-gray-600 dark:text-gray-400 text-center">
                        <p className="text-xs">
                          Mallu Bazar , our terms of use
                          and confirm you have read our{" "}
                          <a
                            href="/Terms"
                            className="underline text-green-900"
                          >
                            Terms and Conditions
                          </a>
                          .
                        </p>
                      </div>
                    </div>
                  </div>
                  ;
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          {isOpen && signUPFlag ? (
            <div className="h-screen w-screen bg-white">
              <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/70 backdrop-blur-sm">
                <div className="relative container m-auto px-6">
                 <div className="m-auto w-full sm:w-10/12 md:w-6/12 lg:w-4/12">
                    <div className="rounded-xl bg-white shadow-xl relative">
                      <button
                        onClick={() => {
                          setSignUPFlag(false);
                          closePopup();
                        }}
                        className="absolute top-4 right-4 text-gren-800 hover:text-gray-900 transition duration-300 text-xl"
                        aria-label="Close"
                      >
                        &times;
                      </button>
                      <div className="p-6">
                        <img
                          src={img}
                          loading="lazy"
                          className="w-16 h-16 object-contain mx-auto"
                          alt="Logo"
                        />
                        <form onSubmit={handleSignUp}>
                          <div className="space-y-6">
                            <div className="mt-4 grid gap-4">
                              <div>
                                <label
                                  htmlFor="name"
                                  className="text-sm text-gray-800 ml-1"
                                >
                                  Name <span className="text-red-500">*</span>
                                </label>
                                <div className="relative mt-2">
                                  <input
                                    onChange={(e) =>
                                      setSignupData({
                                        ...signupData,
                                        name: e.target.value,
                                      })
                                    }
                                    type="text"
                                    id="name"
                                    placeholder="Enter your name"
                                    className="px-4 py-3 bg-white text-gray-800 w-full text-sm border-2 rounded-lg border-gray-200 focus:border-gray-300 outline-none"
                                    required
                                  />
                                </div>
                              </div>
                              <div>
                                <label
                                  htmlFor="email"
                                  className="text-sm text-gray-800 ml-1"
                                >
                                  Email <span className="text-red-500">*</span>
                                </label>
                                <div className="relative mt-2">
                                  <input
                                    onChange={(e) =>
                                      setSignupData({
                                        ...signupData,
                                        email: e.target.value,
                                      })
                                    }
                                    type="email"
                                    id="email"
                                    placeholder="Enter your email"
                                    className="px-4 py-3 bg-white text-gray-800 w-full text-sm border-2 rounded-lg border-gray-200 focus:border-gray-300 outline-none"
                                    required
                                  />
                                </div>
                              </div>
                              <div>
                                <label
                                  htmlFor="phone"
                                  className="text-sm text-gray-800 ml-1"
                                >
                                  Phone Number{" "}
                                  <span className="text-red-500">*</span>
                                </label>
                                <div className="relative mt-2">
                                  <input
                                    onChange={(e) =>
                                      setSignupData({
                                        ...signupData,
                                        mobile: e.target.value,
                                      })
                                    }
                                    type="number"
                                    id="phone"
                                    placeholder="Enter your phone number"
                                    className="px-4 py-3 bg-white text-gray-800 w-full text-sm border-2 rounded-lg border-gray-200 focus:border-gray-300 outline-none"
                                    required
                                  />
                                </div>
                              </div>
                              <div>
                                <label
                                  htmlFor="password"
                                  className="text-sm text-gray-800 ml-1"
                                >
                                  Password{" "}
                                  <span className="text-red-500">*</span>
                                </label>
                                <div className="relative mt-2">
                                  <input
                                    onChange={(e) =>
                                      setSignupData({
                                        ...signupData,
                                        password: e.target.value,
                                      })
                                    }
                                    type={showPassword ? "text" : "password"}
                                    id="password"
                                    placeholder="Enter your password"
                                    className="px-4 py-3 bg-white text-gray-800 w-full text-sm border-2 rounded-lg border-gray-200 focus:border-gray-300 outline-none pr-10"
                                    required
                                  />
                                  <button
                                    type="button"
                                    className="absolute inset-y-0 right-3 flex items-center text-gray-500"
                                    onClick={() =>
                                      setShowPassword(!showPassword)
                                    }
                                  >
                                    {showPassword ? "🙈" : "👁️"}
                                  </button>
                                </div>
                              </div>
                              <div>
                                <label
                                  htmlFor="confirm-password"
                                  className="text-sm text-gray-800 ml-1"
                                >
                                  Confirm Password{" "}
                                  <span className="text-red-500">*</span>
                                </label>
                                <div className="relative mt-2">
                                  <input
                                    type={
                                      showConfirmPassword ? "text" : "password"
                                    }
                                    onChange={(e) =>
                                      setSignupData({
                                        ...signupData,
                                        confirmPassword: e.target.value,
                                      })
                                    }
                                    id="confirm-password"
                                    placeholder="Confirm your password"
                                    className={`px-4 py-3 bg-white text-gray-800 w-full text-sm border-2 rounded-lg ${
                                      signupData.confirmPassword &&
                                      signupData.password !==
                                        signupData.confirmPassword
                                        ? "border-red-500"
                                        : signupData.confirmPassword &&
                                          signupData.password ===
                                            signupData.confirmPassword
                                        ? "border-green-500"
                                        : "border-grey-200"
                                    }  outline-none pr-10`}
                                    required
                                  />
                                  <button
                                    type="button"
                                    className="absolute inset-y-0 right-3 flex items-center text-gray-500"
                                    onClick={() =>
                                      setShowConfirmPassword(
                                        !showConfirmPassword
                                      )
                                    }
                                  >
                                    {showConfirmPassword ? "🙈" : "👁️"}
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="mt-4">
                              <button className="bg-green-800 py-3 w-full text-white rounded-xl hover:bg-green-700 transition duration-300">
                                {loading ? (
                                  <div className="flex items-center justify-center">
                                    <svg
                                      className="animate-spin h-5 w-5 text-white"
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                    >
                                      <circle
                                        className="opacity-25"
                                        cx="12"
                                        cy="12"
                                        r="10"
                                        stroke="currentColor"
                                        strokeWidth="4"
                                      ></circle>
                                      <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                      ></path>
                                    </svg>
                                    <span className="ml-2 text-white">
                                      Loading...
                                    </span>
                                  </div>
                                ) : (
                                  "Sign Up"
                                )}
                              </button>
                            </div>
                          </div>
                        </form>

                        <p className="text-xs text-center mt-2">
                          {" "}
                          Already Have an Account ?{" "}
                          <span
                            onClick={() => setSignUPFlag(false)}
                            className="ml-2 text-green-600 mt-2 text-xs cursor-pointer"
                          >
                            Login
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </>
      )}
    </>
  );
}
