import React from 'react'

export default function Terms() {
  return (
     <div className="max-w-7xl mx-auto  px-6 bg-white p-8 rounded-lg ">
          


         <p className='text-2xl text-left font-bold text-green-800 mb-4 '>TERMS & CONDITIONS</p>

          <p>Welcome to MalluBazar! By using our website or services, you agree to the following terms and conditions. Please read them carefully before placing your order.</p>

          <p className='text-lg font-bold py-2'>1. General</p>
          <p>MalluBazar operates as an online grocery store catering to the Kitchener Waterloo Cambridge  area. Our goal is to provide quality products at affordable prices and fast delivery. By accessing or using our platform, you accept these terms and agree to comply with them</p>

            <p className='text-lg font-bold py-2'>2. Registration and Account</p>
          <p>To place an order, you must register an account with accurate and complete information.<br/>· You are responsible for maintaining the confidentiality of your account credentials and ensuring all activity under your account complies with these terms.</p>
       
        <p className='text-lg font-bold py-2'>3. Orders</p>
          <p>Orders can be placed through our website or Whatsapp Web or Phone number.<br/>· Availability of products is subject to stock. If a product is unavailable, we will notify you and suggest a replacement or process a refund.</p>
       

       <p className='text-lg font-bold py-2'>4. Pricing</p>
       <p>        All prices listed are in CAD and some products have  applicable taxes.<br/>

Prices are subject to change without prior notice, but orders placed before price updates will be honored at the original rate.</p>
       

<p className='text-lg font-bold py-2'>5. Payment</p>

<p>
All payments are done after the delivery of the products. Payment options include:<br/>

o   Cash on Delivery (COD)<br/>

o   Email transfer/ Interac Transfer<br/>

o   Debit card / Credit card payment</p>


<p className='text-lg font-bold py-2'>6. Delivery</p>

<p>
 Same-Day Delivery: We strive to deliver all orders placed before 5:00 PM on the same day, with delivery typically scheduled between 5:00 PM and 7:00 PM.<br/>

 Delivery Charges:<br/>

o   For orders above $50 within the KWC area, delivery is free.<br/>

o   For orders below $50, a delivery charge will apply.<br/>

o   We are actively working toward making all deliveries free regardless of order value, to enhance your shopping experience.<br/>

In case of delays due to unforeseen circumstances, we will notify you promptly.
</p>



<p className='text-lg font-bold py-2'>7. Returns and Refunds</p>


<p>
o   If you receive damaged, expired, or incorrect items, you may request a replacement or refund within 24 hours of delivery.<br/>

o   Refunds will be processed within 5-7 business days.
</p>


<p className='text-lg font-bold py-2'> 8. Privacy Policy</p>
<p>       Your privacy is important to us. We ensure that all your personal and payment information is handled securely and never shared with third parties without consent.  </p>


<p className='text-lg font-bold py-2'>  9. Changes to Terms</p>

<p>
   MalluBazar reserves the right to update or modify these terms at any time. Changes will be communicated via our website or email and take effect immediately upon posting.

Thank you for choosing MalluBazar. We are committed to making your grocery shopping experience affordable, fast, and reliable!</p>
 <div className='py-10'>

        </div>
    </div>
  )
}
