import React from 'react'
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from '@mui/material';
export default function Loading() {
  return (
    <div>
      <Box
      sx={{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        height:'100vh'
      }}
      >
        <CircularProgress color="success" />
      </Box>
    </div>
  );
}
