import React from 'react';
import logo from './Mallu Bazzar Logo_F.png'; // Importing the logo image

export default function About() {
  return (

   
   


      <div className="max-w-7xl mx-auto  px-6 bg-white p-8 rounded-lg ">
          <h1 className="text-2xl text-left font-bold text-green-800 mb-4">About Mallu Bazar</h1>

         
        
       

     
        
       
          <p className="text-lg text-gray-700 leading-relaxed">
         Welcome to MalluBazar, your go-to south Indian online grocery store in the Kitchener Waterloo Cambridge area! We pride ourselves on delivering a seamless shopping experience with a commitment to affordability, speed, and quality.
At MalluBazar, we understand the value of your time and hard-earned money. That’s why we’ve curated a wide range of high-quality products at prices that fit your budget. We have a wide range of products including Spices, breakfast products, frozen snacks, fish, fresh meat, Snacks and everything you need to keep your home stocked and your family happy.
What sets us apart is our lightning-fast home delivery service, ensuring your groceries reach you when you need them the most. Whether you’re planning a meal, hosting a gathering, or simply replenishing your kitchen, MalluBazar is here to make it easy, convenient, and stress-free.
          </p>
          <p className="text-lg  text-gray-700 leading-relaxed mt-4">
            Choose MalluBazar for:
            <ul className="list-disc list-inside mt-4">
              <li className='text-bold mt-1' >Affordable Prices: <span> Get top-quality groceries without breaking the bank.</span> </li>
               <li className='text-bold mt-1' >Fast Delivery: Enjoy same-day or next-day delivery tailored to your schedule</li>
                <li className='text-bold mt-1' >Premium Quality: We guarantee freshness and excellence in every product </li>
            </ul>
              
          </p>
          <p className='mt-4 text-lg text-gray-700'>
            Thank you for making us your trusted partner in grocery shopping. We look forward to serving you and making your everyday life better!
          </p>
          

          <p className='text-xl mt-4 font-bold text-gray-700 ' >Shop Smart. Shop MalluBazar.</p>

       


   

  

   

        {/* <p className="text-lg text-gray-700 mb-4">
          Thank you for making us your trusted partner in grocery shopping. We look forward to serving you and making your everyday life better!
        </p>

        <p className="text-xl font-bold text-gray-700 mbt-6">Shop Smart. Shop MalluBazar.</p>

     
        <div className="mb-6">
          <h3 className="text-xl font-semibold text-green-700 mb-2">1. Contact via Email</h3>
          <p className="text-gray-700">
            For general inquiries, order issues, or product questions, please email us at:
          </p>
          <p className="text-green-600 font-medium">support@mallubazar.com</p>
          <p className="text-gray-700 mt-2">Our team typically responds within 24-48 hours on business days.</p>
        </div>

       
        <div className="mb-6">
          <h3 className="text-xl font-semibold text-green-700 mb-2">2. Phone Support</h3>
          <p className="text-gray-700">
            If you prefer to speak directly with our customer service team, you can reach us at:
          </p>
          <p className="text-green-600 font-medium">Phone: +12268984298</p>
          <p className="text-gray-700 mt-2">
            Operating Hours: Monday – Friday, 9:00 AM to 6:00 PM EST
          </p>
          <p className="text-gray-700 mt-2">Please leave a voicemail if we're unable to take your call, and we will get back to you as soon as possible.</p>
        </div>

      
        <div>
          <h3 className="text-xl font-semibold text-green-700 mb-2">3. Social Media</h3>
          <p className="text-gray-700">
            You can also reach out to us via our social media channels for support or to stay updated on our latest products and offers:
          </p>
          <p className="text-green-600 font-medium mt-2">Instagram: mallubazar_renest</p>
          <p className="text-green-600 font-medium mt-2">WhatsApp: +12268984298</p>
          <p className="text-gray-700 mt-2">We strive to respond to all social media inquiries within 24 hours.</p>
        </div> */}
        <div className='py-10'>

        </div>
      </div>

  );
}
