import React from "react";
import { ClearRefinements, CurrentRefinements, useRefinementList } from "react-instantsearch";
import CategorySearch from "../Categories/CategorySearch";

const CustomRefinementList = ({ attribute, name }) => {
  const {
    items,
    canToggleShowMore,
    isShowingMore,
    toggleShowMore,
    // searchForItems,
    refine,
  } = useRefinementList({
    attribute,
    limit: 5,
    showMoreLimit: 10,
  });

  const hasMoreItems = items.length > 5;

  const handleChange = (event, item) => {
    refine(item.label);
  };


  return (
    <>
      {items.length > 0 ? (
        <>
          {name === "Brands" ? (
            <>
              <div className="flex items-center justify-between w-full">
                <h1 className="text-sm font-medium mb-2">Filter </h1>
                <ClearRefinements
                  translations={{
                    resetButtonText: "Clear All",
                  }}
                  className="text-sm font-medium cursor-pointer"
                />
                <button
                  className="md:hidden text-gray-500 text-2xl"
                  onClick={() =>
                    document
                      .getElementById("filterPanel")
                      .classList.toggle("translate-x-0")
                  }
                >
                  &times;
                </button>
              </div>
              <div className="flex items-center justify-between w-full pb-3 border-b border-gray-200 "></div>
              {/* <div className="filter-content"> */}
              <CurrentRefinements
                excludedAttributes={["rating", "query"]}
                classNames={{
                  root: "list-none",
                  noRefinementRoot: "hidden",
                  list: "flex flex-wrap gap-4",
                  label: "text-sm font-medium text-gray-800",
                  category: "custom-category",
                  categoryLabel: "custom-category-label",
                  delete: "custom-delete-button",
                }}
              />
              {/* </div> */}
            </>
          ) : null}

          <div className={`filter-section py-2`}>
            <h3 className="text-lg font-bold mb-2">{name}</h3>
            <div className="flex items-center justify-between w-full border-b border-gray-200 mb-5" />
            <div className="filter-content custom-refinement-list">
              <div className="refinement-list">
                {/* <div className="relative mb-2">
                  <input
                    className="w-full border text-sm border-green-800 rounded-md  pl-7 bg-green-50 text-green-800 focus:border-green-800 focus:ring-green-200 focus:outline-none transition duration-200 ease-in-out"
                    style={{ height: 25 }}
                    placeholder={`Search ${name}`}
                    type="search"
                    autoComplete="off"
                    autoCorrect="off"
                    autoCapitalize="off"
                    spellCheck={false}
                    maxLength={512}
                    onChange={(event) =>
                      searchForItems(event.currentTarget.value)
                    }
                  />
                  <span className="absolute left-2 top-1.5 text-green-800">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      fill="currentColor"
                      class="bi bi-search"
                      viewBox="0 0 16 16"
                    >
                      <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                    </svg>
                  </span>
                </div> */}
                {items.length === 0 ? (
                  <p className="text-sm font-medium">No results found</p>
                ) : (
                  <ul className="refinement-list__list py-2">
                    {items.map((item) => (
                      <li key={item.label} className="refinement-list__item">
                        <label className="refinement-list__label flex items-center">
                          <input
                            onChange={(event) => handleChange(event, item)}
                            checked={item.isRefined}
                            type="checkbox"
                            value={item.label}
                            className="accent-green-800 "
                          />
                          <p className="ml-2 text-sm font-medium">
                            {item.label}
                          </p>
                          <span className="font-medium"> ({item.count})</span>
                        </label>
                      </li>
                    ))}
                  </ul>
                )}

                {hasMoreItems && canToggleShowMore && (
                  <button
                    onClick={toggleShowMore}
                    className="text-green-800 px-2 py-2 cursor-pointer mt-2"
                  >
                    {isShowingMore ? "Show Less" : "Show More"}
                  </button>
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>{name==="Brands" &&
          <CategorySearch />
        }
        </>
      )}
    </>
  );
};

export default CustomRefinementList;
