import React, { useEffect, useState } from "react";
import * as Toast from "@radix-ui/react-toast";
import { useInstantSearch } from "react-instantsearch";

function SearchErrorToast() {
  const { addMiddlewares } = useInstantSearch();
  const [error, setError] = useState(null);

  useEffect(() => {
    const middleware = ({ instantSearchInstance }) => {
      function handleError(searchError) {
        setError(searchError);
      }

      return {
        subscribe() {
          instantSearchInstance.addListener("error", handleError);
        },
        unsubscribe() {
          instantSearchInstance.removeListener("error", handleError);
        },
      };
    };

    return addMiddlewares(middleware);
  }, [addMiddlewares]);

  if (!error) {
    return null;
  }

  return (
    <Toast.Provider>
      <Toast.Root
        onOpenChange={(isOpen) => {
          if (!isOpen) {
            setError(null);
          }
        }}
      >
        <Toast.Title>{error.name}</Toast.Title>
        <Toast.Description>{error.message}</Toast.Description>
      </Toast.Root>

      <Toast.Viewport />
    </Toast.Provider>
  );
}

export default SearchErrorToast;
