import React, { useEffect, useState } from "react";
import {
  InstantSearch,
  Hits,
  Configure,
  useInstantSearch,
} from "react-instantsearch";
import algoliasearch from "algoliasearch/lite";
import "./s.css";
import "instantsearch.css/themes/reset.css";
import { RangeSlider } from "./RangeSlider/RangeSlider";
import StarRatingFilter from "./StarRatingFilter/StarRatingFilter";
import { useLocation, useNavigate } from "react-router-dom";
import { useCartContext } from "../Product/CartContext";
import { auth, db } from "../../Admin/Config/Config";
import SearchErrorToast from "./HandlingErrors/SearchErrorToast";
import CustomRefinementList from "./CustomRefinements/CustomRefinementList";
import { doc, getDoc } from "firebase/firestore";
import Pagination from "./Pagination/Pagination";
import { useToast } from "../../Toast.jsx/Toast";

const searchClient = algoliasearch(
  "VEF1B0E57Q",
  "8ebb486478eb1824b05b609042c32526"
);

const SearchPage = () => {
  const [term, setTerm] = useState("");
  const location = useLocation();
  const [triggerCat, setTriggerCat] = useState('');

  // ====================================== Query params ====================================== //
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const queryParam = params.get("query");
    const cat = params.get("f");
    if (queryParam) {
      setTerm(queryParam);
    }
      if (cat) {
        setTriggerCat(cat?cat:'');
      } else {
        setTriggerCat('');
      }
  }, [location]);


  return (
    <div>
      <InstantSearch searchClient={searchClient} indexName="products">
        <Configure query={term} />
        <div className="py-4 px-2 ml-2">
          <button
            className="md:hidden bg-green-800 text-white px-4 py-2 rounded"
            onClick={() =>
              document
                .getElementById("filterPanel")
                .classList.toggle("translate-x-0")
            }
          >
            Filters
          </button>
        </div>

        <div className="flex flex-col md:flex-row min-h-screen  bg-white">
          <>
            {/* Background overlay */}
            <div
              id="overlay"
              className="fixed inset-0 bg-black bg-opacity-50 z-50 hidden md:hidden"
              onClick={() =>
                document
                  .getElementById("filterPanel")
                  .classList.toggle("translate-x-0")
              }
            />

            {/* Filter Panel */}
            <aside
              id="filterPanel"
              className="filter-panel bg-white shadow p-4 fixed md:relative top-0 left-0 w-full md:w-1/4 h-full transform -translate-x-full md:translate-x-0 transition-transform duration-300 z-40 md:z-30"
            >
              {/* Additional Filter Sections */}

              <CustomRefinementList attribute="brand" name="Brands" />
              <CustomRefinementList attribute="category" name="Categories" />
              <RangeSlider attribute="offer_price" />
              <StarRatingFilter />
            </aside>
          </>

          <main className="flex-grow p-2 md:w-3/4 w-full">
            <Configure filters="offer_price > 0" />
            <Configure filters="rating > 0" />
            <Configure filters={`status:"${true}"`} />

            {triggerCat && <Configure filters={`category:"${triggerCat}"`} />}

            <SearchErrorToast />
            <NoResultsBoundary fallback={<NoResults />}>
              <Configure hitsPerPage={20} />
              <Hits hitComponent={Hit} />
            </NoResultsBoundary>
          </main>
        </div>
        <div className="flex  justify-center items-center">
          <Pagination />
        </div>
        <div className="flex py-12 justify-center items-center"></div>
      </InstantSearch>
    </div>
  );
};

const Hit = ({ hit }) => {
  const navigate = useNavigate();
  const renderStars = (rating) => {
    const totalStars = 5;
    return Array.from({ length: totalStars }, (_, index) => (
      <span
        key={index}
        className={index < rating ? "text-yellow-500" : "text-gray-300"}
      >
        ★
      </span>
    ));
  };
  // ================================ Navigate to view page ================================ //
  const handleDivClick = async (id) => {
    navigate(`/view?query=${encodeURIComponent(id)}`);
  };

  // ================================ Navigate to view page ================================ //

  const { addToCart } = useCartContext();
  // const { openPopup } = useLoginPopup();
  const [loading, setLoading] = useState({state: false, id: ""});
      const { showWarning } = useToast();
      const handleAddToCart = async (e, product) => {
        e.stopPropagation();
        if (product.stock < 1) {
          showWarning("Out of stock");
          return;
        }
        if (!auth.currentUser) {
          setLoading({ state: true, id: product.objectID });
          const docSnapshot = await getDoc(
            doc(db, "products", product.objectID)
          );
          if (docSnapshot.exists()) {
            addToCart("", {
              id: product.objectID,
              ...docSnapshot.data(),
            });
            setLoading({ state: false, id: "" });
          } else {
            alert("Something went wrong.. Please try again later.");
            setLoading({ state: false, id: "" });
          }
        } else {
          setLoading({ state: true, id: product.objectID });
          const docSnapshot = await getDoc(
            doc(db, "products", product.objectID)
          );
          if (docSnapshot.exists()) {
            addToCart(auth.currentUser.uid, {
              id: product.objectID,
              ...docSnapshot.data(),
            });
            setLoading({ state: false, id: "" });
          } else {
            alert("Something went wrong.. Please try again later.");
            setLoading({ state: false, id: "" });
          }
        }
      };

   const [isImageLoading, setIsImageLoading] = useState(true);
  return (
    <div className="hit  ">
      <div className="mx-auto   p-2">
        <div
          onClick={(e) => {
            handleDivClick(hit.objectID);
          }}
          key={hit.objectID}
          className="bg-white  p-4 border border-gray-400 border-dotted rounded-lg text-left relative"
        >
          <div className="w-full h-44 mb-4 rounded relative overflow-hidden">
            {isImageLoading && (
              <div className="absolute inset-0 bg-gray-300 animate-pulse rounded"></div>
            )}
            <img
              src={hit.thumb_url}
              alt={hit.product_name}
              className={`w-full h-44 object-contain rounded transition-opacity duration-500 ${
                isImageLoading ? "opacity-0" : "opacity-100"
              }`}
              onLoad={() => setIsImageLoading(false)}
            />
          </div>

         
          {hit.stock <= 0 ? (
            <button className="bg-red-500 text-white py-1 px-2 rounded-full absolute top-1 left-1">
              Out of stock
            </button>
          ) : (
            <>
              {((hit.original_price - hit.offer_price) / hit.original_price) *
                100 >=
              10 ? (
                <button className="bg-[#1a572b] text-white py-1 px-2 rounded-full absolute top-1 left-1">
                  {(
                    ((hit.original_price - hit.offer_price) /
                      hit.original_price) *
                    100
                  ).toFixed(0)}
                  % Off
                </button>
              ) : null}
            </>
          )}

          <div className="flex items-center justify-between">
            <h2 className="text-sm font-medium">{hit.product_name}</h2>
          </div>
          <div className="py-1">
            <p className="text-[#1a572b] font-medium text-lg">
              ${hit.offer_price}&nbsp;
              <span>
                {hit.original_price>hit.offer_price ?
                <strike className="hidden sm:inline text-red-500 text-sm">
                  ${hit.original_price}
                </strike>:null
                }
              </span>
              {hit.tax_applicable && (
                <span className="text-xs text-gray-600">&ensp;(HST) </span>
              )}
            </p>
          </div>

          <div className="flex justify-between ">
            <p className="text-sm py-1 text-gray-500">{hit.brand}</p>
            <p className="text-sm py-1 text-gray-500">
              {hit.quantity}&nbsp;{hit.measure_unit}
            </p>
          </div>

          <div className="flex items-center justify-between">
            <div className="flex items-center">{renderStars(hit.rating)}</div>
            <button
              onClick={(e) => handleAddToCart(e, hit)}
              className="bg-[#1a572b] text-white w-8 h-8 rounded-full flex items-center justify-center hover:bg-green-800 transition duration-300"
            >
              {loading.state === true && loading.id === hit.objectID ? (
                <label>Loading...</label>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-cart-plus"
                  viewBox="0 0 16 16"
                >
                  <path d="M9 5.5a.5.5 0 0 0-1 0V7H6.5a.5.5 0 0 0 0 1H8v1.5a.5.5 0 0 0 1 0V8h1.5a.5.5 0 0 0 0-1H9z" />
                  <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1zm3.915 10L3.102 4h10.796l-1.313 7zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0m7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                </svg>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
function NoResultsBoundary({ children, fallback, onNoResults }) {
  const { results } = useInstantSearch();

  if (!results.__isArtificial && results.nbHits === 0) {
    return (
      <>
        {fallback}
        <div hidden>{children}</div>
      </>
    );
  }
  return children;
}

function NoResults() {
  const { indexUiState } = useInstantSearch();

  return (
    <div>
      <p>
        No results for <q>{indexUiState.configure.query}</q>.
      </p>
    </div>
  );
}


export default SearchPage;
