import React from 'react'

export default function Emptycart() {
  return (
    <div>
       <div className="flex flex-col items-center justify-center py-24">
      <img 
        src="https://cdn.dribbble.com/users/687236/screenshots/5838300/group_852_2x.png" 
        alt="Empty Cart" 
        className="w-64 h-64"
      />
      <h2 className="text-2xl font-semibold text-gray-700 mt-4">
        Your cart is empty
      </h2>
      <p className="text-gray-500 text-center mt-2">
        Looks like you haven't added any items to your cart yet.
      </p>
      
    </div>
    </div>
  )
}
