import React from 'react';

export default function Profileempty() {
  return (
    <div className="flex flex-col items-center justify-center py-24">
      <img 
        src="https://ndal-alis.gov.in/armslicence/resources/images/login_screen_img.jpg" 
        alt="Empty Wishlist" 
        className="w-64 h-64"
      />
      <h2 className="text-xl font-medium text-gray-700 mt-4">
        Please Log In
      </h2>
      <p className="text-gray-500 text-center mt-2">
        You need to log in to access your profile
      </p>
     
    </div>
  );
}
