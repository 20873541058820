import React, { useEffect, useState } from "react";
import {
  useRefinementList,
  useClearRefinements,
  useCurrentRefinements,
} from "react-instantsearch";
import Rating from "@mui/material/Rating";

const StarRatingFilter = () => {
  // ============================ Refinement attributes and functions ======================= //
  const { items, refine } = useRefinementList({ attribute: "rating" });
  const { refine: clearRefinements } = useClearRefinements({
    includedAttributes: ["rating"],
  });
  const { items: currentRefinements } = useCurrentRefinements();
  // ============================ Clearing rating ============================= //

  useEffect(() => {
    const isRatingRefined = currentRefinements.some(
      (refinement) => refinement.attribute === "rating"
    );
    if (!isRatingRefined) {
      setSelectedRating(null);
    }
  }, [currentRefinements]);

  // ============================ Rating Filter ============================= //
  const [selectedRating, setSelectedRating] = useState(null);
  const handleRatingChange = (event, newValue) => {
    clearRefinements();
    if (newValue === null) {
      
      setSelectedRating(null);
      refine([]);
    } else {
      setSelectedRating(newValue);
      refine([newValue]);
    }
  };

  return (
    <>
      {items.length > 0 ? (
        <div>
          <h3 className="font-medium text-sm">Filter by Rating</h3>

          <Rating
            className="py-4"
            name="rating-controlled"
            value={selectedRating}
            onChange={handleRatingChange}
            precision={1}
            size="large"
          />
        </div>
      ) : null}
    </>
  );
};

export default StarRatingFilter;
