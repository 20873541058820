import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { collection, getDocs, orderBy, query, where } from 'firebase/firestore';
import { db } from '../../Admin/Config/Config';
import { useHomedata } from '../../Admin/Homedata/HomedataProvider';
import './Banner.css';  // Import custom CSS file

export default function Banner() {
  const [bannerList, setBannerList] = useState([]);
 // const [loadedImages, setLoadedImages] = useState({});
  const homeData = useHomedata();

  // Fetching banners from Firestore
  const fetchBanners = async () => {
    const ref = collection(db, 'banner');
    const q = query(
      ref,
      where("status", "==", true),
      where("banner_position", "==", "bannertwo"),
      orderBy("two_order", "asc")
    );
    const querySnapshot = await getDocs(q);
    const banners = [];
    querySnapshot.forEach((doc) => {
      banners.push({ id: doc.id, ...doc.data() });
    });
    setBannerList(banners);

    // Initialize the loadedImages state with false for all banners
    const initialLoadedState = {};
    banners.forEach((banner) => {
      initialLoadedState[banner.id] = false;
    });
   // setLoadedImages(initialLoadedState);
  };

  // Effect to fetch banners once the component is mounted
  useEffect(() => {
    fetchBanners();
  }, []);

  // Handle image load event to set the image as loaded
  // const handleImageLoad = (id) => {
  //   setLoadedImages((prevState) => ({ ...prevState, [id]: true }));
  // };

  const [imageLoad, setImageLoad] = useState(false);
    const handleBananerClick = (banner) => {
      if (banner.type === "webpage") {
        window.open(`https://${banner.link}`, "_blank", "noopener,noreferrer");
      } else if (banner.type === "whatsapp") {
        window.open(`https://wa.me/${banner.number}`, "_blank");
      }
    };
  return (
    <>
      {homeData && homeData.banner_2 ? (
        <div className="banner-container mx-auto max-w-8xl px-4 sm:px-6 lg:px-8 mt-4 rounded-xl">
          <Carousel
            className="rounded-xl"
            showArrows={true}
            autoPlay={true}
            infiniteLoop={true}
            showThumbs={false}
            showStatus={false}
            interval={3000}
          >
            {bannerList.map((banner) => (
              <div
                key={banner.id}
                style={{ cursor: "pointer" }}
                onClick={() => handleBananerClick(banner)}
              >
                {!imageLoad ? (
                  <>
                    <div className="banner-shimmer w-full h-96 rounded-lg" />
                    <img
                      onLoad={() => setImageLoad(true)}
                      alt="Banner"
                      src={banner.img_url}
                      style={{ display: "none" }}
                    ></img>
                  </>
                ) : (
                  <>
                    <img
                      src={banner.img_url}
                      alt="Banner"
                      onLoad={() => setImageLoad(true)}
                    />
                  </>
                )}
              </div>
            ))}
          </Carousel>
        </div>
      ) : null}
    </>
  );
}
