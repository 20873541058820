import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { HomedataProvider } from './components/Admin/Homedata/HomedataProvider';
import { LoginProvider } from './components/Auth/LoginContext';
import { LoadingProvider } from './components/assets/Loading/LoadingContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <HomedataProvider>
        <LoginProvider>
          <LoadingProvider>
            <App />
          </LoadingProvider>
        </LoginProvider>
      </HomedataProvider>
    </BrowserRouter>
  </React.StrictMode>
);
serviceWorkerRegistration.register();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
