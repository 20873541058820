import React, {  useEffect, useState } from 'react';
import { FaShoppingCart, FaHeart, FaUser, FaHome,FaMicrosoft } from 'react-icons/fa';

import { Link, useNavigate } from 'react-router-dom';
import logo from './assets/logo.png';
import { useHomedata } from '../Admin/Homedata/HomedataProvider';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useLoginPopup } from '../Auth/LoginContext';
import { useWishlist } from '../Home/Product/WishListProvider';
import { useCartContext } from '../Home/Product/CartContext';
import { auth } from '../Admin/Config/Config';
import AutocompleteWithRefine from '../Home/Search/AutoComplete/AutoComplete';

const Navbar = (user) => {
  const homeData = useHomedata();
  const {cart, fetchCart} = useCartContext();
  const [isOpen, setIsOpen] = useState(false);
  const { openPopup } = useLoginPopup();
  const navigate = useNavigate();

useEffect(() => {
  const unsubscribe = onAuthStateChanged(auth, (user) => {
    if (user) {
      fetchCart(user.uid);
    } else {
      fetchCart("");
    }
  });
  return () => unsubscribe();
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [auth]);

  const {wishlistData} = useWishlist();


const [wishlist, setWishlist] = useState();
useEffect(() => {
  setWishlist(wishlistData.length)
} , [wishlistData])

window.addEventListener("cartUpdated", () => {
  fetchCart("")
  console.log("cart updated")
})

  return (
    <div className="flex mx-auto   sticky top-0 z-10 flex-col ">
      {homeData && homeData.discount_status ? (
        <div className="bg-[#1a572b] text-white text-center py-2 hidden sm:block relative ">
          <span>🌟 {homeData.discount_text} 🌟</span>
        </div>
      ) : null}

      <nav className="bg-white sticky text-[#1a572b]  shadow border">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center h-20">
            <a href="/" className="flex items-center space-x-4">
              <img
                alt="The Goodwill Academy logo"
                src={logo}
                className="w-32 h-16 sm:h-16 lg:ml-2 "
              />
              
            </a>

            <div className="flex-grow  relative">
              <div className="px-2">
                 <AutocompleteWithRefine />
                
              </div>
              
            </div>
            

           
            <div className="hidden md:flex items-center space-x-8">
              <div className="flex space-x-8 items-center">
                <Link
                  to="/cart"
                  className="relative flex items-center text-[#1a572b] hover:text-[#1a572b]"
                >
                  <FaShoppingCart className="h-6 w-6" />
                  <span className="text-xs text-black ml-1">Cart</span>
                  {cart&& cart.length>0?
                  <span className="absolute -top-4 -right-2 bg-red-500 text-white text-xs rounded-full px-1">
                    {cart.length}
                  </span>
                  :null}
                </Link>
                <Link
                  to="/whislist"
                  className="relative flex items-center text-[#1a572b] hover:text-[#1a572b]"
                >
                  <FaHeart className="h-6 w-6" />
                  <span className="text-xs text-black ml-1">Wishlist</span>
                  {wishlist > 0 && (
                    <span className="absolute -top-4 -right-2 bg-red-500 text-white text-xs rounded-full px-1">
                      {wishlist}
                    </span>
                  )}
                </Link>
              </div>

              <div className="flex items-center space-x-4">
                {!getAuth().currentUser ? (
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={openPopup}
                    className="inline-flex bg-gray-300 py-2 px-4 items-center justify-center p-2 rounded-md text-[#000000] hover:text-[#1a572b] focus:outline-none"
                  >
                    Login
                  </div>
                ) : (
                  <>
                    <a
                      href="/profile"
                      className="h-10 w-10 rounded-full bg-gray-200 flex items-center justify-center"
                    >
                    {console.log(getAuth().currentUser.photoURL?true:false)}
                      <img
                        src={
                          getAuth().currentUser
                            && getAuth().currentUser.photoURL?getAuth().currentUser.photoURL
                            : "https://uxwing.com/wp-content/themes/uxwing/download/peoples-avatars/default-avatar-profile-picture-male-icon.png"
                        }
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src =
                            "https://uxwing.com/wp-content/themes/uxwing/download/peoples-avatars/default-avatar-profile-picture-male-icon.png";
                        }}
                        alt="Profile Avatar"
                        className="h-8 w-8 rounded-full"
                      />
                    </a>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate("/profile");
                      }}
                    >
                      <span className="text-sm text-[#1a572b]">
                        {user.name}
                      </span>
                    </div>
                  </>
                )}
              </div>
              
            </div>

            {/* Hamburger Menu for Mobile */}
            <div className="-mr-2 flex md:hidden">
            
              {!getAuth().currentUser ? (
                <div
                  onClick={openPopup}
                  className="inline-flex  py-1 items-center justify-center p-2 rounded-md text-[#000000] hover:text-[#1a572b] focus:outline-none"
                >
                  Login
                </div>
              ) : null}
              <button
                onClick={() => setIsOpen(!isOpen)}
                className="inline-flex items-center justify-center p-2 rounded-md text-[#1a572b] hover:text-[#1a572b] focus:outline-none"
              >
                <svg
                  className={`h-10 w-8 ${isOpen ? "hidden" : "block"}`}
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
                <svg
                  className={`h-6 w-6 ${isOpen ? "block" : "hidden"}`}
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>

 
        {isOpen && (
          <div className="md:hidden bg-white">
            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
              <Link
                to="/"
                className="block text-lg text-[#1a572b] hover:bg-green-100 px-3 py-2 rounded-md"
              >
                Home
              </Link>
              <Link
                to="/cart"
                className="block text-lg text-[#1a572b] hover:bg-green-100 px-3 py-2 rounded-md"
              >
                Cart
              </Link>

              
              <Link
                to="/whislist"
                className="block text-lg text-[#1a572b] hover:bg-green-100 px-3 py-2 rounded-md"
              >
                Wishlist
              </Link>
              <Link
                to="/profile"
                className="block text-lg text-[#1a572b] hover:bg-green-100 px-3 py-2 rounded-md"
              >
                Profile
              </Link>

              {/* Search bar in mobile */}
              {/* <div className="py-2">
                <div className="flex">
                  <select className="p-2 border border-gray-300 rounded-l-md focus:outline-none focus:ring-2 focus:ring-green-[#1a572b]">
                    <option value="">Category</option>
                    <option value="fruits">Fruits</option>
                    <option value="vegetables">Vegetables</option>
                    <option value="dairy">Dairy</option>
                  </select>
                  <input
                    type="text"
                    placeholder="Search products..."
                    className="w-full p-2 bg-gray-100 border border-gray-300 rounded-r-md focus:outline-none focus:ring-2 focus:ring-green-[#1a572b"
                  />
                </div>
              </div> */}
            </div>
          </div>
        )}
      </nav>

      {/* Bottom Navigation (Only on Mobile) */}
      <div className="fixed bottom-0 left-0 right-0 bg-white shadow-lg md:hidden">
        <div className="flex justify-around items-center h-16">
          {/* Home Link */}
          <Link
            to="/"
            className="flex flex-col items-center bg-gray-50 rounded-xl px-2 py-1 text-[#1a572b] hover:text-green-700"
          >
            <FaHome className="w-6 h-6" />
            <span className="text-xs">Home</span>
          </Link>

          {/* Cart Link with Badge */}
          <div className="relative flex flex-col items-center">
            <Link
              to="/cart"
              className="flex flex-col items-center text-[#1a572b] hover:text-green-700"
            >
              <FaShoppingCart className="w-6 h-6" />
              <span className="text-xs">Cart</span>
            </Link>

            {cart && cart.length > 0 && (
              <span className="absolute top-0 right-0 transform translate-x-2 -translate-y-2 bg-red-500 text-white text-xs rounded-full w-5 h-5 flex items-center justify-center">
                {cart.length}
              </span>
            )}
          </div>

 <Link
            to="/Categories"
            className="flex flex-col items-center text-[#1a572b] hover:text-green-700"
          >
            <FaMicrosoft className="w-6 h-6" />
            <span className="text-xs">Categories </span>
          </Link>



          <div className="relative flex flex-col items-center">
            <Link
              to="/whislist"
              className="flex flex-col items-center text-[#1a572b] hover:text-green-700"
            >
              <FaHeart className="w-6 h-6" />
              <span className="text-xs">Wishlist</span>
            </Link>

            {wishlist > 0 && (
              <span className="absolute top-0 right-0 transform translate-x-2 -translate-y-2 bg-red-500 text-white text-xs rounded-full w-5 h-5 flex items-center justify-center">
                {wishlist}
              </span>
            )}
          </div>

          {/* Profile Link */}
          <Link
            to="/profile"
            className="flex flex-col items-center text-[#1a572b] hover:text-green-700"
          >
            <FaUser className="w-6 h-6" />
            <span className="text-xs">Profile</span>
          </Link>
        </div>
      </div>

 <a
      href="https://wa.me/+12268984298"
      target="_blank"
      rel="noopener noreferrer"
       className="fixed bottom-16 right-3 bg-green-600   text-white p-4 rounded-full shadow-lg hover:bg-green-600 transition-colors duration-75 flex items-center justify-center z-50 blinking-animation"
      title="Chat with us on WhatsApp"
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-whatsapp" viewBox="0 0 16 16">
        <path d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232"/>
      </svg>
    </a>


    </div>
  );
};

export default Navbar;
