import React, { useEffect, useState } from "react";
import Navbar from "./components/Navbar/Navbar";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import Home from "./components/Home/Home";
import View from "./components/Home/Product/View";
import Cart from "./components/Home/Product/Cart";
import Whislist from "./components/Home/Product/Whislist";
import Profile from "./components/Home/Profile/Profile";
import Login from "./components/Auth/Login";
import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from "./components/Admin/Config/Config";
import { doc, onSnapshot, setDoc } from "firebase/firestore";
import { useHomedata } from "./components/Admin/Homedata/HomedataProvider";
import { WishlistProvider } from "./components/Home/Product/WishListProvider";
import { SearchProvider } from "./components/Home/Search/SearchProvider";
import SearchPage from "./components/Home/Search/SearchPage";
import CartProvider from "./components/Home/Product/CartContext";
import Billingaddress from "./components/Home/Product/Billingaddress";
import Checkout from "./components/Home/Product/Checkout";
import EmptyWishlist from "./components/Home/Product/Emptywhislist";
import Edit from "./components/Home/Profile/Edit";
import { ToastProvider } from "./components/Toast.jsx/Toast";
import Orderhistory from "./components/Home/Profile/Orderhistory";
import Orderstatus from "./components/Home/Product/Orderstatus";
import Items from "./components/Home/Profile/Items";
import { RelatedProductsProvider } from "./components/Home/Product/RelatedProducts/RelatedProducts";
import ProductLists from "./components/Home/Product/ProductLists/ProductLists";
import Loading from "./components/assets/Loading/Loading";
import Categories from "./components/Mob/Categories";
import Faq from "./components/Home/Footer/Faq";
import About from "./components/Home/Footer/About";
import Comingsoon from "./components/Comigsoon/Comingsoon";
import Terms from "./components/Home/Footer/Terms";
import Privacypolicy from "./components/Home/Footer/Privacypolicy";
const App = () => {
  const [userDetails, setUserDetails] = useState(null);
  const homedata = useHomedata();
  const [loading, setloading] = useState(true);
  const checkUserInFirestore = (user) => {
    const uid = user.uid;
    const userDocRef = doc(db, "users", uid);
    const unsubscribe = onSnapshot(userDocRef, async (docu) => {
      if (!docu.exists()) {
        await setDoc(doc(db, "cart", uid), {
          items: [],
        });
          await setDoc(userDocRef, {
            uid: uid,
            name: user.displayName,
            email: user.email,
            photoURL: user.photoURL,
            createdAt: new Date(),
          });
        setUserDetails({
          uid: uid,
          name: user.displayName,
          email: user.email,
          photoURL: user.photoURL,
          createdAt: new Date(),
        });
        setloading(false);
      } else {
        setUserDetails(docu.data());
        setloading(false);
      }
    });
    return unsubscribe;
  };

  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, (user) => {
      if (user) {
        const unsubscribeFirestore = checkUserInFirestore(user);
        return () => unsubscribeFirestore();
      } else {
        console.log("User signed out.");
        setUserDetails(null);
      }
    });
    return () => unsubscribeAuth();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

      useEffect(() => {
        if(homedata){
            setloading(false);
        }           
      }, [homedata,loading]);

      


  return (
    <div>
      {!loading ? (
        <>
          <ToastProvider>
            <CartProvider>
              <SearchProvider>
                <WishlistProvider>
                  <RelatedProductsProvider>
                    <Navbar {...userDetails} />
                    <Routes>
                      <Route index element={<Home />} />
                      <Route path="/view" element={<View />} />
                      <Route path="/cart" element={<Cart />} />
                      <Route
                        path="/profile"
                        element={<Profile {...userDetails} />}
                      />

                      <Route path="/whislist" element={<Whislist />} />
                      <Route path="/search-items" element={<SearchPage />} />
                      <Route
                        path="/billing-address"
                        element={<Billingaddress />}
                      />

                      <Route path="/checkout" element={<Checkout />} />

                      <Route
                        path="/emptywhislist"
                        element={<EmptyWishlist />}
                      />

                      <Route path="/Edit" element={<Edit />} />

                      <Route
                        path="/orderhistory"
                        element={<Orderhistory {...userDetails} />}
                      />
                      <Route path="/product-lists" element={<ProductLists />} />
                      <Route path="/orderstatus" element={<Orderstatus />} />
                      <Route path="/items" element={<Items />} />
                      <Route path="/Categories" element={<Categories />} />
                      <Route path="/faq" element={<Faq />} />
                      <Route path="/About" element={<About />} />
                      <Route path="/comingsoon" element={<Comingsoon />} />
                        <Route path="/Terms" element={<Terms/>} />
                        <Route path="/policy" element={<Privacypolicy/>} />

                    </Routes>

                    <Login />
                  </RelatedProductsProvider>
                </WishlistProvider>
              </SearchProvider>
            </CartProvider>
          </ToastProvider>
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default App;
