import React from 'react'

export default function Privacypolicy() {
  return (
      <div className="max-w-7xl mx-auto  px-6 bg-white p-8 rounded-lg ">
          


         <p className='text-2xl text-left font-bold text-green-800 mb-4'>Cancellation & Refund Policy</p>
    

<p className='mt-2'>At MalluBazar, we strive to provide a hassle-free shopping experience. Our cancellation and refund policy is designed to ensure customer satisfaction while maintaining transparency.</p>


<p className='text-lg font-bold py-2'> 1. Order Cancellation</p>

<p>
 Before Dispatch:

o   You can cancel your order anytime before it has been dispatched.<br/>

o   To cancel, contact us via our customer support channels (phone, email, or whatsapp).<br/>

o   If payment has already been made, a full refund will be processed.<br/>

After Dispatch:<br/>

o   Once the order is out for delivery, cancellations are not allowed.<br/>

o   However, if you are unsatisfied with a delivered product, you can request a return or refund (see below).
</p>


<p className='text-lg font-bold py-2'>2.Refund Policy</p>
<p>We offer refunds under the following circumstances:<br/>

1.     Damaged or Incorrect Products:<br/>

o   If you receive a damaged, defective, or incorrect item, you may request a refund or replacement within 24 hours of delivery.<br/>

2.     Non-Delivery:<br/>

o   If an order is not delivered due to an error on our part, a full refund will be provided.<br/>

3.     Cancellations Before Dispatch:<br/>

o   Orders canceled before dispatch are eligible for a full refund.</p>





<p className='text-lg font-bold py-2'>3. Refund Process</p>
<p>
  · Refunds will be processed through the original payment method.<br/>

· For online payments, refunds typically take 5-7 business days to reflect in your account.<br/>

· For Cash on Delivery (COD) orders, refunds will be issued as store credits or via a preferred payment method upon confirmation.
</p>


<p className='text-lg font-bold py-2'> 4. How to Request a Refund?</p>
<p>
  To initiate a refund or return:<br/>

1.     Contact our customer support team at mallubazar@gmail.com or call +1-226-898-4298<br/>

2.     Provide your order details and the reason for the refund request.<br/>

3.     Attach supporting evidence, such as a photo of the damaged or incorrect product, if applicable.
</p>

<p className='text-lg font-bold py-2'> 5. Conditions for Refunds and Returns</p>
<p>· Refund requests must be made within 24 hours of delivery.<br/>

· Items must be in their original packaging, unused, and intact (except for damaged items).<br/>

· Certain items (e.g., perishable goods) may not be eligible for return unless they are defective or damaged.<br/><br/>

At MalluBazar, your satisfaction is our priority. We’re committed to resolving issues promptly and ensuring a smooth shopping experience.</p>

 <div className='py-10'>

        </div>
    </div>
  )
}
