import { collection, getDocs, limit, orderBy, query, where } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { db } from '../../../Admin/Config/Config';
import { useNavigate } from 'react-router-dom';

export default function CategorySearch() {
    const [category, setCategory] = useState([]);
    const [searchKey, setSearchKey] = useState('');
    const navigate = useNavigate();

    const fetchCategories = async (q) => {
      setCategory([]);
      const querySnapshot = await getDocs(q);
      const reviews = [];
      querySnapshot.forEach((doc) => {
        reviews.push({ id: doc.id, ...doc.data() });
      });
      setCategory(reviews);
    };

    useEffect(() => {
        const q = query(collection(db, "category"), orderBy("order", "asc"), where("status", "==", true));
        fetchCategories(q);
    }, []);

    const handleSearch = (searchTerm) => {
        const q = query(    
          collection(db, "category"),
          where("searchKey", ">=", searchTerm.toUpperCase()),
          where("searchKey", "<=", searchTerm.toUpperCase() + "\uf8ff"),
          limit(10)
        );
        fetchCategories(q);
    };

    const handleClose = () => {
        navigate(-1); // Navigate back to the previous page
    };

    return (
      <div className="min-h-screen">
        <div className="flex justify-between  ">
          <h1 className="text-lg font-medium px-2 mb-2">Pick any category</h1>
          <button
            onClick={handleClose}
            className="flex items-center text-green-800 font-medium hover:text-green-600 transition duration-200 ease-in-out md:hidden"
          >
            <svg
              className="h-6 w-6 mr-1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M6.293 4.293a1 1 0 011.414 0L10 6.586l2.293-2.293a1 1 0 111.414 1.414L11.414 8l2.293 2.293a1 1 0 11-1.414 1.414L10 9.414l-2.293 2.293a1 1 0 01-1.414-1.414L8.586 8 6.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>

        <div className="px-2">
          {/* Search Input */}
          <input
            className="w-full border border-green-800 text-sm text-green-800 bg-gray-50 rounded-md pl-2 pr-4 py-2 focus:border-green-800 focus:ring-green-200 focus:outline-none transition duration-200 ease-in-out"
            placeholder="🔍 Search a category"
            type="search"
            value={searchKey}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck={false}
            maxLength={512}
            onChange={(event) => {
              handleSearch(event.currentTarget.value);
              setSearchKey(event.currentTarget.value);
            }}
          />

          {/* Refinement List */}
          <ul className="refinement-list mt-4 space-y-2">
            {category &&
              category.map((item) => (
                <li
                  key={item.id}
                  className="refinement-list__item hover:bg-green-50 p-3 rounded-lg cursor-pointer transition duration-200 ease-in-out"
                >
                  <button
                    onClick={() =>
                      navigate(
                        `/search-items?query=${encodeURIComponent(
                          item.category_name
                        )}&f=${item.category_name}`
                      )
                    }
                    className="w-full text-left text-green-800 font-medium text-sm hover:text-green-900 transition-colors duration-200 ease-in-out"
                  >
                    {item.category_name}
                  </button>
                </li>
              ))}
          </ul>
        </div>
      </div>
    );
}
