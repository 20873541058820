// src/context/WishlistContext.js
import React, { createContext, useState, useEffect, useContext } from "react";
import { collection, query, where, onSnapshot } from "firebase/firestore";
import { db, auth } from "../../Admin/Config/Config";
import { onAuthStateChanged } from "firebase/auth"; // Import onAuthStateChanged

const WishlistContext = createContext();

export const useWishlist = () => useContext(WishlistContext);

export const WishlistProvider = ({ children }) => {
  const [wishlistData, setwishlistData] = useState([]);


  useEffect(() => {
    let unsubscribe;

    const authListener = onAuthStateChanged(auth, (user) => {
      if (user) {
        const q = query(
          collection(db, "products"),
          where("liked_users", "array-contains", user.uid) 
        );

        unsubscribe = onSnapshot(q, (querySnapshot) => {
          const products = [];
          if (!querySnapshot.empty) { 
          querySnapshot.forEach((doc) => {
            products.push({ id: doc.id, ...doc.data() });
          });
          setwishlistData(products);
        } else {
          setwishlistData([]);
        }
        });
      } else {
        setwishlistData([]); 
      }
    });

    return () => {
      authListener(); 
      if (unsubscribe) unsubscribe(); 
    };
  }, []);

  return (
    <WishlistContext.Provider value={{ wishlistData }}>
      {children}
    </WishlistContext.Provider>
  );
};
