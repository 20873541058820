import React, { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/material";
import { useLoading } from "./LoadingContext";

export default function DianamicLoading() {
  const { loading } = useLoading(); 
  const [load, setLoad] = useState(true);

  useEffect(() => {
    if (loading) {
      setLoad(true);
    } else {
      setLoad(false);
    }
  }, [loading]);
  if (!load) return null; 

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <CircularProgress color="success" />
    </Box>
  );
}
