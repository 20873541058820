import React, { useEffect, useState } from "react";
import 'bootstrap-icons/font/bootstrap-icons.css';
import { collection, getDocs, limit, orderBy, query, where } from "firebase/firestore";
import { db } from "../../Admin/Config/Config";

export default function Review() {
  // ============================================================ Fetch Reviews =========================================================== //
  const [reviews, setReviews] = useState([]);
  const fetchReviews = async () => {
    const q = query(
      collection(db, "customer_reviews"),
      where("display", "==", true),
      orderBy("order", "asc"),
      limit(20)
    );
    const querySnapshot = await getDocs(q);
    const reviews = [];
    querySnapshot.forEach((doc) => {
      reviews.push({ id: doc.id, ...doc.data() });
    });
    setReviews(reviews);
  }
  useEffect(() => {
    fetchReviews();
  }, [])
  return (
    <div>
      {reviews && (
        <div className="flex justify-between items-center mx-auto max-w-8xl px-4 sm:px-6 lg:px-12 mt-4">
          <h2 className="text-right text-xl text-gray-600 font-bold">
            Customer Reviews
          </h2>
        </div>
      )}
     <div className="mx-auto max-w-8xl lg:px-8 px-4 py-6">
  <div className="flex md:grid md:grid-cols-2 lg:flex lg:flex-nowrap gap-4 overflow-x-auto mx-auto hide-scrollbar max-h-[600px] px-4">
    {reviews &&
      reviews.map((review) => (
        <div className="w-full h-full border rounded-xl px-6 py-6 space-y-4 min-w-[300px]">
          <p>{review.feedback}</p>
          <div className="text-yellow-500 flex gap-2">
            {[...Array(5)].map((_, index) => (
              <i
                key={index}
                className={
                  index < review.user_rating
                    ? "bi bi-star-fill"
                    : "bi bi-star"
                }
              ></i>
            ))}
          </div>
          <div className="flex gap-2">
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png?20150327203541"
              alt="avatar"
              className="h-12 w-12 border rounded-full"
            />
            <span className="text-gray-600 ml-2 mt-1 text-sm font-normal">
              {review.user_name}
              <br />
              Customer
            </span>
          </div>
        </div>
      ))}
  </div>
</div>

    </div>
  );
}
