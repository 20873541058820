import React from 'react'

export default function Emptywhislist() {
  return (
    <div>
      <div className="flex flex-col items-center justify-center py-24 ">
      <img 
        src="https://www.masho.com/assets/images/orders/emptybag.png" 
        alt="Empty Wishlist" 
        className="w-64 h-64"
      />
      <h2 className="text-2xl font-semibold text-gray-700 mt-4">
        Your wishlist is empty
      </h2>
      <p className="text-gray-500 text-center mt-2">
        Looks like you haven't added anything to your wishlist yet.
      </p>
      {/* <button className="mt-6 px-4 py-2 bg-green-800 text-white rounded-lg">
        Start Shopping
      </button> */}
    </div>
    
    </div>
  )
}
