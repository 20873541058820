import React, { useRef, useEffect, useState } from "react";
import algoliasearch from "algoliasearch/lite";
import { autocomplete, getAlgoliaResults } from "@algolia/autocomplete-js";
import "@algolia/autocomplete-theme-classic";
import { useNavigate } from "react-router-dom";
import { useSearchContext } from "../SearchProvider";

const searchClient = algoliasearch(
  "VEF1B0E57Q",
  "8ebb486478eb1824b05b609042c32526"
);

const AutocompleteWithRefine = () => {
  const navigate = useNavigate();
  const containerRef = useRef(null);
  const [selectedTerm, setSelectedTerm] = useState("");
  const { setSearchTerm } = useSearchContext();
  useEffect(() => {
    if (!containerRef.current) {
      return undefined;
    }
    const search = autocomplete({
      container: containerRef.current,
      placeholder: "Search....",
      getSources({ query }) {
        return [
          {
            sourceId: "products",
            getItems() {
              return getAlgoliaResults({
                searchClient,
                queries: [
                  {
                    indexName: "products",
                    query,
                    params: {
                      hitsPerPage: 5,
                      filters: "status: true",
                    },
                  },
                ],
              });
            },
            templates: {
              item({ item, html }) {
                return html`
                  <div class="flex items-center p-1 py-4  gap-4">
                    <!-- Product Image -->
                    <img
                      src="${item.thumb_url}"
                      alt="${item.product_name}"
                      class="w-10 h-10 object-contain rounded-md"
                    />

                    <!-- Product Details -->
                    <div class="flex flex-col">
                      <span class="font-medium text-md"
                        >${item.product_name}</span
                      >
                      <span class="text-sm mt-1 text-gray-500"
                        >${item.category}</span
                      >
                    </div>
                  </div>
                `;
              },
            },

            onSelect({ item }) {
                setSelectedTerm(item.product_name);
                localStorage.setItem("searchTerm", item.product_name);
                navigate(`/view?query=${encodeURIComponent(item.objectID)}`);
            },
          },
        ];
      },
      initialState: {
        query: selectedTerm
          ? selectedTerm
          : localStorage.getItem("searchTerm")
          ? localStorage.getItem("searchTerm")
          : "", 
      },
      onSubmit({ state }) {
        if (
          state.collections.length > 0 && state.query.trim().length
        ) { 
            localStorage.setItem("searchTerm", state.query);
            navigate(`/search-items?query=${encodeURIComponent(state.query)}`);
        }
      },
    });

    return () => {
      search.destroy();
    };
  }, [setSearchTerm, navigate, selectedTerm]);

  useEffect(() => {
    if (containerRef.current && selectedTerm) {
      const input = containerRef.current.querySelector("input");
      if (input) {
        input.value = selectedTerm;
      }
    }
  }, [selectedTerm]);

  return <div  ref={containerRef}/>;
};

export default AutocompleteWithRefine;
