import React from "react";
import { usePagination } from "react-instantsearch";

const Pagination = () => {
  const { pages, refine, currentRefinement, isFirstPage, isLastPage } =
    usePagination();

  return (
    <div className="pagination flex flex-wrap items-center justify-center gap-2 mt-4 px-2">
      <button
        className={`flex items-center justify-center w-10 h-10 rounded-lg font-medium transition-colors duration-200 ${
          isFirstPage
            ? "bg-gray-200 text-gray-500 cursor-not-allowed"
            : "bg-white border border-gray-300 hover:bg-gray-100 text-gray-700"
        }`}
        disabled={isFirstPage}
        onClick={() => refine(currentRefinement - 1)}
        aria-label="Previous Page"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={2}
          stroke="currentColor"
          className="w-5 h-5"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15.75 19.5L8.25 12l7.5-7.5"
          />
        </svg>
      </button>
      {pages.map((page) => (
        <button
          key={page}
          className={`w-10 h-10 rounded-lg font-medium flex items-center justify-center transition-colors duration-200 ${
            page === currentRefinement
              ? "bg-green-600 text-white border border-green-600"
              : "bg-white border border-gray-300 hover:bg-green-100 text-gray-700"
          }`}
          onClick={() => refine(page)}
          aria-label={`Page ${page + 1}`}
        >
          {page + 1}
        </button>
      ))}
      <button
        className={`flex items-center justify-center w-10 h-10 rounded-lg font-medium transition-colors duration-200 ${
          isLastPage
            ? "bg-gray-200 text-gray-500 cursor-not-allowed"
            : "bg-white border border-gray-300 hover:bg-gray-100 text-gray-700"
        }`}
        disabled={isLastPage}
        onClick={() => refine(currentRefinement + 1)}
        aria-label="Next Page"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={2}
          stroke="currentColor"
          className="w-5 h-5"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M8.25 4.5l7.5 7.5-7.5 7.5"
          />
        </svg>
      </button>
    </div>
  );
};

export default Pagination;
