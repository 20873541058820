import React, { useEffect, useState, useRef } from 'react';
import { useHomedata } from '../../Admin/Homedata/HomedataProvider';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, limit, orderBy, query, where } from 'firebase/firestore';
import { db } from '../../Admin/Config/Config';

export default function Brand() {
  const homedata = useHomedata();
  // ========================================================================== Fetching Category List ========================================================================
  const [categoryList, setCategoryList] = useState([]);
  const navigate = useNavigate();
  const fetchCateogories = async () => {
    const q = query(
      collection(db, "brands"),
      where("status", "==", true),
      orderBy("order", "asc"),
      limit(50)
    );
    const querySnapshot = await getDocs(q);
    const categories = [];
    querySnapshot.forEach((doc) => {
      categories.push({ id: doc.id, ...doc.data() });
    });
    setCategoryList(categories);
  };

  useEffect(() => {
    fetchCateogories();
  }, []);


  const navigationPage = (category) => {
    navigate(`/search-items?query=${encodeURIComponent(category)}`);
  };



  const scrollContainerRef = useRef(null);

  // Function to handle scrolling
  const handleScroll = () => {
    const container = scrollContainerRef.current;
    if (container) {
      const scrollAmount = container.offsetWidth; // Scroll by the width of the container
      container.scrollBy({ left: scrollAmount, behavior: "smooth" });
    }
  };

  return (
    <>
      {homedata && homedata.is_row4 ? (
        <>
        <div className="relative">
      <section className="bg-white">
        <div className="py-4 lg:py-12 mx-auto max-w-8xl px-4 sm:px-6 lg:px-6 mt-4">
          <div className="flex justify-between items-center mx-auto max-w-8xl px-4 sm:px-6 lg:px-8 mb-4">
            <h2 className="text-right text-xl font-medium">
              {homedata && homedata.row4}
            </h2>
          </div>

          <div className="relative px-4 py-4">
           
            <div
              className="overflow-x-auto flex gap-14 scrollbar-hide relative"
              ref={scrollContainerRef}
            >
              {categoryList.map((category) => (
                <div
                  key={category.id}
                  style={{
                    cursor: "pointer",
                    minWidth: "calc(100% / 8)", 
                  }}
                  onClick={() => navigationPage(category.brand_name)}
                  className="flex justify-center  items-center group"
                >
                  <img
                    src={category.img_url}
                    alt="brand"
                    className="h-20 w-20 object-contain rounded-2xl"
                  />
                </div>
              ))}
            </div>

         
            <div
              className="absolute top-1/2 right-0 transform -translate-y-1/2 h-10 w-10 flex items-center justify-center bg-gray-200 rounded-full  cursor-pointer"
              onClick={handleScroll}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 text-green-800"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </div>
          </div>
        </div>
      </section>
    </div>

        </>
      ) : null}
    </>
  );
}
