import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { db } from "../Admin/Config/Config";

export default function Categories() {
  const [categoryList, setCategoryList] = useState([]);

  const fetchCategories = async () => {
    const q = query(
      collection(db, "category"),
      where("status", "==", true),
      orderBy("order", "asc")
    );
    const querySnapshot = await getDocs(q);
    const categories = [];
    querySnapshot.forEach((doc) => {
      categories.push({ id: doc.id, ...doc.data() });
    });
    setCategoryList(categories);
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  return (
    <div className="p-4">
      <div className="grid grid-cols-3 gap-4">
        {categoryList.map((category) => (
          <CategoryCard key={category.id} category={category} />
        ))}
      </div>
      <div className="py-8">

      </div>
    </div>
  );
}

function CategoryCard({ category }) {
  const [isLoaded, setIsLoaded] = useState(false);
  const navigate = useNavigate();

  const navigationPage = (category) => {
    navigate(`/search-items?query=${encodeURIComponent(category)}&f=${category}`);
  };

  return (



 <div
      className="cursor-pointer"
      onClick={() => navigationPage(category.category_name)}
    >
      <div style={{ width: "100px", height: "100px" }} className="flex flex-col bg-gray-50 h-48 w-full items-center p-4 border border-dotted rounded-xl">
        <div className="relative w-34 h-34 mb-2">
          {!isLoaded && (
            <div className="absolute inset-0 rounded-full shimmer-placeholder" />
          )}
          <img
            src={category.image}
            alt={category.category_name}
            className={`w-34 h-34 object-contain mx-auto justify-center items-center rounded-full ${
              isLoaded ? "" : "hidden"
            }`}
            onLoad={() => setIsLoaded(true)}
          />
        </div>
      </div>
     <span className="text-sm font-medium mt-2 ml-1 text-gray-700">
        {category.category_name}
      </span>
    </div>

    

   


       
    

      


   
   
  );
}
