import React, { useEffect, useState } from 'react';
import { auth, db } from '../../Admin/Config/Config';
import { arrayRemove, arrayUnion, doc, updateDoc } from 'firebase/firestore';
import { useWishlist } from './WishListProvider';
import { useLoginPopup } from '../../Auth/LoginContext';
import { useNavigate } from 'react-router-dom';
import { useCartContext } from './CartContext';
import Emptywhislist from './Emptywhislist';
import { useToast } from '../../Toast.jsx/Toast';

// Helper function to generate star ratings
const renderStars = (rating) => {
  const totalStars = 5;
  return Array.from({ length: totalStars }, (_, index) => (
    <span key={index} className={index < rating ? "text-yellow-500" : "text-gray-300"}>
      ★
    </span>
  ));
};

export default function Whislist() {
  const navigate = useNavigate();
  const { wishlistData } = useWishlist();
  const { showWarning } = useToast();
  const { openPopup } = useLoginPopup();
  const [wishlist, setWishlist] = useState([]);
  const [imageLoading, setImageLoading] = useState(true); // State to track image loading

  useEffect(() => {
    if (auth.currentUser) {
      setWishlist(wishlistData);
    } else {
      openPopup();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wishlistData]);

  const toggleLike = async (e, id, liked) => {
    e.stopPropagation();
    if (auth.currentUser) {
      const userId = auth.currentUser.uid;
      await updateDoc(doc(db, "products", id), {
        liked_users: liked ? arrayRemove(userId) : arrayUnion(userId),
      });
    }
  };

  const handleDivClick = async (id) => {
    navigate(`/view?query=${encodeURIComponent(id)}`);
  };

  const { addToCart } = useCartContext();
  const handleAddToCart = async (e, product) => {
    e.stopPropagation();
     if (product.stock < 1) {
       showWarning("Out of stock");
       return;
     }
    if (!auth.currentUser) {
      openPopup();
    } else {
      await addToCart(auth.currentUser.uid, product);
    }
  };

  const handleImageLoad = () => {
    setImageLoading(false); // Set loading to false when image is loaded
  };

  return (
    <div className="p-4 my-12">
      {wishlist && wishlist.length > 0 ? (
        <div className="mx-auto max-w-8xl lg:px-8 grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4">
          {wishlist.map((product) => {
            const liked = product.liked_users?.includes(auth.currentUser?.uid);
            return (
              <div
                onClick={() => {
                  handleDivClick(product.id);
                }}
                key={product.id}
                className="bg-white p-4 border border-gray-400 border-dotted rounded-lg text-left relative"
              >
                {/* Shimmer Effect while Image is loading */}
                <div
                  className={`w-full h-44 mb-4 rounded ${
                    imageLoading ? "bg-gray-300 animate-pulse" : ""
                  }`}
                >
                  <img
                    src={product.thumb_url}
                    alt={product.product_name}
                    className="w-full h-44 object-contain rounded"
                    onLoad={handleImageLoad} // Trigger when image is loaded
                    style={{ display: imageLoading ? "none" : "block" }} // Hide image until loaded
                  />
                </div>

                <button
                  onClick={(e) => toggleLike(e, product.id, liked)}
                  className={`rounded-full flex items-center justify-center transition duration-300 absolute top-1 right-1 ${
                    liked ? "" : "bg-gray-100"
                  }`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    className="bi bi-heart"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M8 2.748l-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01z"
                      fill={liked ? "red" : "none"}
                      stroke={liked ? "none" : "currentColor"}
                      strokeWidth={liked ? 0 : 1}
                    />
                  </svg>
                </button>

                {product.stock <= 0 ? (
                  <button className="bg-red-500 text-white py-1 px-2 rounded-full absolute top-1 left-1">
                    Out of stock
                  </button>
                ) : (
                  <>
                    {((product.original_price - product.offer_price) /
                      product.original_price) *
                      100 >=
                    10 ? (
                      <button className="bg-[#1a572b] text-white py-1 px-2 rounded-full absolute top-1 left-1">
                        {(
                          ((product.original_price - product.offer_price) /
                            product.original_price) *
                          100
                        ).toFixed(0)}
                        % Off
                      </button>
                    ) : null}
                  </>
                )}

                <div className="flex items-center justify-between py-2">
                  <h2 className="text-base font-medium">
                    {product.product_name.length > 18
                      ? `${product.product_name.substring(0, 17)}...`
                      : product.product_name}
                  </h2>
                </div>
                <div className="flex items-center justify-between">
                  <p className="text-[#1a572b] font-medium text-base">
                    ${product.offer_price.toFixed(2)}
                  </p>
                  <p className="text-red-500 font-medium text-xs line-through">
                    {product.original_price > product.offer_price
                      ? ` $ ${product.original_price.toFixed(2)}`
                      : null}
                  </p>

                  <p className="text-sm py-1 ml-2 text-gray-500">
                    {product.quantity}&nbsp;
                    <span className="font-light italic">
                      {product.measure_unit}
                    </span>
                  </p>
                </div>

                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    {renderStars(product.rating)}
                  </div>
                  <button
                    onClick={(e) => handleAddToCart(e, product)}
                    className="bg-[#1a572b] text-white w-8 h-8 rounded-full flex items-center justify-center hover:bg-green-800 transition duration-300"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-cart-plus"
                      viewBox="0 0 16 16"
                    >
                      <path d="M9 5.5a.5.5 0 0 0-1 0V7H6.5a.5.5 0 0 0 0 1H8v1.5a.5.5 0 0 0 1 0V8h1.5a.5.5 0 0 0 0-1H9z" />
                      <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1zm3.915 10L3.102 4h10.796l-1.313 7zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0m7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                    </svg>
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <Emptywhislist />
      )}
    </div>
  );
}
