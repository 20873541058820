import React, { createContext, useContext, useState } from "react";
import { doc, getDoc} from "firebase/firestore";
import { db } from "../../../Admin/Config/Config";

const RelatedProductsContext = createContext();

export const RelatedProductsProvider = ({ children }) => {
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [load, setLoad] = useState(false);

  const fetchRelatedProductsByIds = async (relatedProductIds) => {
    setLoad(true);
    const productsList = [];

    try {
      for (const id of relatedProductIds) {
        const productDoc = await getDoc(doc(db, "products", id.id));
        if (productDoc.exists()) {
          productsList.push({
            id: productDoc.id,
            ...productDoc.data(),
          });
        }
      }
      setRelatedProducts(productsList);
    } catch (error) {
      console.error("Error fetching related products:", error);
    } finally {
      setLoad(false);
    }
  };

  return (
    <RelatedProductsContext.Provider
      value={{ relatedProducts, load, fetchRelatedProductsByIds }}
    >
      {children}
    </RelatedProductsContext.Provider>
  );
};

export const useRelatedProducts = () => useContext(RelatedProductsContext);

