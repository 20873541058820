import { createContext, useState, useContext } from "react";

const LoginContext = createContext();

export const LoginProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false); 

  const openPopup = () => setIsOpen(true);  const closePopup = () => setIsOpen(false); 
  return (
    <LoginContext.Provider value={{ isOpen, openPopup, closePopup }}>
      {children}
    </LoginContext.Provider>
  );
};

export const useLoginPopup = () => useContext(LoginContext);
