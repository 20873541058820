import React, { useEffect } from 'react'
import Slider from './Slider'
import Banner from './Banner/Banner'
import Features from './Features.jsx/Features'
import Product from './Product/Product'
import Footer from './Footer/Footeer'
import Bestratedbestprice from './Catgories/Bestratedbestprice'
import Homeessential from './Catgories/Homeessential'
import Mostoderd from './Catgories/Mostoderd'
import Brand from './Brands/Brand'
import Review from './Review/Review'
import { HomedataProvider } from '../Admin/Homedata/HomedataProvider'
import Banner2 from './Banner/Banner2'
import DianamicLoading from '../assets/Loading/DianamicLoading'
export default function Home() {
  useEffect(() => {
    localStorage.setItem("searchTerm", "");
  },[])

  return (
    <div>
    <DianamicLoading/>
      <HomedataProvider>
        <Slider />
        <Banner />
        <Product />
        <Features />
        <Homeessential />
        <Brand />
        <Bestratedbestprice />
        <Banner2 />
        <Mostoderd />
        <Review />
        <Footer />
      </HomedataProvider>
    </div>
  );
}
