import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

export default function Orderstatus() {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (!location.state) {
      window.location.href = "/";
    }
  }, [location]);
  // =============================== Control back button =============================== //

  useEffect(() => {
    const handlePopState = (event) => {
      navigate("/cart");
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <div className="flex flex-col items-center justify-center py-24">
        <img
          src="https://cdn.vectorstock.com/i/500p/93/35/mobile-payment-success-vector-51539335.jpg"
          alt="Empty Cart"
          className="w-64 h-64"
        />
        <h2 className="text-sm font-medium text-gray-900 mt-4">
          Your Order Has Been Placed
        </h2>
        <p className="text-gray-500 mt-2">
          Track your order here
          <span
            onClick={() => {
              navigate("/orderhistory", {
                state: {
                  order: {id: location.state.order.orderId},
                },
              });
            }}
            className="text-green-600 ml-2 cursor-pointer"
          >
            Track Now
          </span>
        </p>
      </div>
    </div>
  );
}


