import React from 'react';

export default function Faq() {
  const faqs = [
    {
      question: "What is MalluBazar?",
      answer: "MalluBazar is an online grocery store in the KWC area, offering a wide range of high-quality products at affordable prices with fast delivery services.",
    },
    {
      question: "How do I place an order?",
      answer: " You can place an order by: Visiting our website, Browsing through our product categories.Adding items to your cart.Proceeding to checkout and completing payment.",
    },
    {
      question: "What are your delivery timings?",
      answer: "Orders placed before 5:00 PM will be delivered the same day between 5:00 PM and 7:00 PM.Orders placed after 5:00 PM will be scheduled for the next available delivery slot.",
    },
    {
      question: "Is there a delivery charge?",
      answer: "Delivery is free for orders above $50 within the KWC area.For orders below $50, a nominal delivery fee applies.We are working towards offering free delivery on all orders soon!",
    },
    {
      question: "What payment methods do you accept?",
      answer: "We accept:Cash on Delivery (COD), Credit/Debit Card, Interac transfer/ Email transfer",
    },
    {
      question: "Can I track my order?",
      answer: "You will receive an order confirmation email. You can track the order in you account section in website.",
    },
    {
      question: "What is your return and refund policy?",
      answer: "If you receive a damaged, expired, or incorrect product, contact us within 24 hours of delivery for a replacement or refund.Refunds are processed within 5-7 business days.",
    },
    {
      question: "Do you have any discounts or offers?",
      answer: "Yes, we regularly provide exclusive deals and promotions. Keep an eye on our website or follow us on Instagram or Join our whatsapp groups.",
    },
    {
      question: "How do I contact customer support?",
      answer: "You can reach us through,Email: Info.mallubazar@gmail.com,Phone: +1-226-898-4298",
    },
    
  ];

  return (
    <div className="min-h-screen  py-6 px-6">
      <div className="max-w-4xl mx-auto bg-white rounded-lg p-4">
        <h1 className="text-2xl font-bold text-green-800 mb-8 text-left mt-2">
        Frequently Asked Questions (FAQ) – MalluBazar
        </h1>
        <div className="space-y-6">
          {faqs.map((faq, index) => (
            <div
              key={index}
              className="border-b border-gray-200 pb-4"
            >
              <h2 className="text-xl font-semibold text-gray-800">
                {faq.question}
              </h2>
              <p className="text-gray-600 mt-2">{faq.answer}</p>
             
            </div>

            
          ))}
        </div>
         <p className='text-gray-900 font-bold mt-6'> Still have questions? Feel free to contact us—we’re here to help!</p>
      </div>
       <div className='py-10'>

        </div>
    </div>
  );
}
