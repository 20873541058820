import React, { useEffect, useState } from "react";
import { useCartContext } from "./CartContext";
import { app, auth, db } from "../../Admin/Config/Config";
import Loading from "../../assets/Loading/Loading";
import { useLoginPopup } from "../../Auth/LoginContext";
import { useLocation, useNavigate } from 'react-router-dom';
import { useToast } from "../../Toast.jsx/Toast";
import { getFunctions, httpsCallable } from "firebase/functions";
import { doc, getDoc } from "firebase/firestore";

export default function Checkout() {
  const { cart, fetchCart } = useCartContext();
  const { openPopup } = useLoginPopup();
  const { showSuccess, showWarning } = useToast();
  const [appliedPromo, setAppliedPromo] = useState(null);
  const [promoValues, setPromoValues] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState(true);
  const [totalAmount, setTotalAmount] = useState(0);
  // =============================== data promo =============================== //
  useEffect(() => {
    setAppliedPromo(location.state.appliedPromo);
    setPromoValues(location.state.promoValues);
  }, [location]);
  useEffect(() => {
    if (auth.currentUser) {
      const fetch = async () => {
        setLoading(true);
        await fetchCart(auth.currentUser.uid);
        setLoading(false);
      };
      fetch();
    } else {
      openPopup();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [taxAmount, settaxAmount] = useState(0);
  useEffect(() => {
    if (cart) {
      let total = 0;
      let taxTotal = 0;
      cart.forEach((item) => {
        total += item.offer_price * item.cart_quantity;
        if (item.tax_applicable === true) {
          taxTotal += item.offer_price * item.cart_quantity;
        }
      });
      const tax = ((taxTotal / 100) * 13).toFixed(2);
      settaxAmount(parseFloat(tax));
      setTotalAmount(parseFloat(total.toFixed(2)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart]);

  // =============================== Apply Promo Code ================================ //

  const applyPromoCode = async (code) => {
    setLoading(true);
    const functions = getFunctions(app, "us-central1");
    const additem = httpsCallable(functions, "checkPromo");
    const reqData = {
      promoId: code,
      order: true,
      promoFlag: appliedPromo ? true : false,
      address: location.state.address,
    };
    additem(reqData).then((result) => {
      const data = result.data;
      if (data.code === 200) {
        showSuccess(data.message);
        navigateToCheckout(data.orderId);
      } else {
        showWarning(data.message);
        setLoading(false);
      }
    });
  };
  const navigateToCheckout = async (id) => {
    const docRef = doc(db, "orders", id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      navigate("/orderstatus", {
        state: {
          order: { orderId: id, label: "Order placed" },
        },
      });
    }
  };


  const [isLoaded, setIsLoaded] = useState(false);

  const handleImageLoad = () => {
    setIsLoaded(true); 
  };
  // insrtuctions
  const [instructions,setInstructions] = useState("");
  useEffect(()=>{
    const fetchInstructions = async()=>{
        const docRef = doc(db, "instructions", "instructions");
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setInstructions(docSnap.data().payment_delivery);
        }
    }
    fetchInstructions();
  },[])
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div class="container mx-auto mt-2 ">
          <div class="sm:flex max-w-8xl my-6 ">
            <div class="  w-full   sm:w-3/4 bg-white px-4 ">
              <div class="flex justify-between  pb-8">
                <h1 class="font-semibold text-[#1a572b] text-lg">
                  Shopping Cart
                </h1>
                {cart && cart.length > 0 ? (
                  <h2 class="font-semibold text-[#1a572b] text-lg">
                    {cart.length} Items
                  </h2>
                ) : null}
              </div>
              {cart &&
                cart.map((item) => (
                  <div>
                    {item.stock < 1 ? (
                      <p class="text-xs leading-3 text-red-600 md:pt-0 pt-4">
                        Not in Stock
                      </p>
                    ) : null}
                    <div className="md:flex border border-gray-300 border-dotted rounded-xl items-stretch py-4 md:py-10 lg:py-8 px-6">
                      <div className="md:w-4/12 py-6 2xl:w-1/4 w-full">
                        <div className="relative">
                          <div
                            className={`absolute inset-0 w-34 h-24 mx-auto bg-gradient-to-r from-gray-200 to-gray-400 animate-pulse ${
                              isLoaded ? "hidden" : "block"
                            }`}
                            style={{ borderRadius: "12px" }}
                          ></div>

                          <img
                            src={item.thumb_url}
                            alt="product"
                            onLoad={handleImageLoad}
                            className={`w-auto h-24 mx-auto object-center object-cover md:block hidden rounded-lg  transition-opacity duration-500 ease-in-out ${
                              isLoaded ? "" : "opacity-0"
                            }`}
                          />

                          <img
                            src={item.thumb_url}
                            alt="product"
                            onLoad={handleImageLoad}
                            className={`md:hidden w-auto mx-auto h-24 object-contain mb-4 rounded-lg transition-opacity duration-500 ease-in-out ${
                              isLoaded ? "" : "opacity-0"
                            }`}
                          />
                        </div>
                      </div>

                      <div className="md:pl-5 md:w-8/12 2xl:w-3/4 flex flex-col justify-center">
                        <p className="text-xl font-semibold text-gray-800 md:pt-0  transition-colors duration-300">
                          {item.product_name}&ensp;
                          {item.tax_applicable === true
                            ? `(HST Applicable)`
                            : ""}
                        </p>

                        <div className="flex items-center justify-between mt-1 w-full">
                          <p className="text-md font-medium text-gray-700">
                            {item.brand}
                          </p>
                        </div>

                        <div className="flex items-center justify-between mt-1 w-full">
                          <p className=" text-xs font-bold text-gray-700">
                            Qty : {item.cart_quantity}
                          </p>
                        </div>

                        <div className="flex items-center justify-between pt-4">
                          {item.offer_price && item.cart_quantity ? (
                            <p className="font-medium text-lg text-green-800">
                              ${" "}
                              {(
                                parseFloat(item.offer_price) *
                                parseInt(item.cart_quantity)
                              ).toFixed(2)}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <br />
                  </div>
                ))}

              <a
                href="/"
                className="text-green-700 ml-2  mt-2 flex items-center cursor-pointer"
              >
                Continue Shopping
                <svg
                  className="w-4 h-4 mt-1 ml-1"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M12.293 3.293a1 1 0 011.414 0l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414-1.414L15.586 10H3a1 1 0 110-2h12.586l-3.293-3.293a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </a>
              <br />
            </div>
            <div
              id="summary"
              class=" w-full   sm:w-1/4   md:w-1/2     px-8 pb-8 "
            >
              <h1 class="font-semibold text-2xl border-b pb-4">
                Order Summary
              </h1>
              <div class="flex justify-between mt-6 ">
                <span class="font-semibold text-sm uppercase">
                  Items ({cart.length})
                </span>
                <span class="font-semibold text-green-800 text-sm">
                  $&nbsp;{totalAmount}
                </span>
              </div>
              {appliedPromo && (
                <div class="flex justify-between mt-5 ">
                  <span class="font-semibold text-sm uppercase">
                    Promo Code Discount
                  </span>
                  <span class="font-semibold text-green-800 text-sm">
                    $ &nbsp;{promoValues ? promoValues.discount : 0}
                  </span>
                </div>
              )}
              <div>
                {appliedPromo && (
                  <>
                    <div className="mt-4">
                      <div className="flex bg-green-50 text-green-800 border border-dashed border-green-500 rounded-lg p-4 justify-between items-center mt-2">
                        <p className="text-xs text-green-800">
                          {appliedPromo.code} Applied 🎉
                        </p>
                      </div>
                    </div>
                  </>
                )}
              </div>

              <div className="flex justify-between mt-2"></div>
              <span className="font-semibold text-green-800 text-xs mt-2">
                {instructions}
              </span>

              <div className="mt-3 border-t pt-2">
                <h2 className="font-semibold text-lg flex items-center justify-between">
                  <div className="flex items-center">
                    <i className="bi bi-truck text-green-800"></i>
                    &nbsp;Delivery Address
                  </div>
                </h2>
                <div className="mt-2">
                  <p className="text-sm">Name: {location.state.address.name}</p>
                  <p className="text-sm">
                    Mobile: {location.state.address.mobile}
                  </p>
                  <p className="text-sm">
                    Email: {location.state.address.email}
                  </p>
                  <p className="text-sm mt-2 flex flex-wrap gap-2">
                    <span>Address:</span>
                    {location.state.address.unit_number?
                    <span>{location.state.address.unit_number},</span>:null
                    }
                    <span>{location.state.address.street},</span>
                    <span>{location.state.address.city},</span>
                    <span>{location.state.address.zip},</span>
                    <span>{location.state.address.state},</span>
                    <span>{location.state.address.country}</span>
                  </p>
                </div>
              </div>

              <div className="mt-2 border-t pt-3 flex items-center justify-between">
                <h2 className="font-semibold text-lg flex items-center">
                  <i className="bi bi-cash text-green-800"></i>&nbsp; Payment
                  Method
                </h2>
                <div className="flex items-center">
                  <label htmlFor="cod" className="text-gray-700">
                    (COD)
                  </label>
                </div>
              </div>

              <div class="border-t mt-2">
                <div class="flex font-semibold justify-between mt-2 text-sm uppercase">
                  <span>Sub total</span>
                  {cart.length > 0 && totalAmount ? (
                    <span>
                      $&nbsp;
                      {totalAmount}
                      {/* {promoValues ? (parseFloat(promoValues.final)-parseFloat(taxAmount)).toFixed(2) : totalAmount} */}
                    </span>
                  ) : null}
                </div>
                {taxAmount > 0 && (
                  <div class="flex font-semibold justify-between mt-3 text-sm uppercase">
                    <span>HST</span>
                    {cart.length > 0 && totalAmount ? (
                      <span>
                        $&nbsp;
                        {taxAmount ? taxAmount : 0}
                      </span>
                    ) : null}
                  </div>
                )}
                {promoValues && promoValues.discount > 0 && (
                  <div class="flex font-semibold justify-between mt-3 text-sm uppercase">
                    <span>Discount</span>

                    <span>
                      $&nbsp; {promoValues ? promoValues.discount : 0}
                    </span>
                  </div>
                )}
                <div class="flex font-semibold justify-between mt-3 text-sm uppercase">
                  <span>Total cost</span>
                  {cart.length > 0 && totalAmount ? (
                    <span>
                      $&nbsp;
                      {promoValues
                        ? promoValues.final
                        : (
                            parseFloat(totalAmount) + parseFloat(taxAmount)
                          ).toFixed(2)}
                    </span>
                  ) : null}
                </div>
                <button
                  onClick={() => applyPromoCode(appliedPromo.id)}
                  disabled={
                    cart.some((item) => item.stock < 1) || !instructions
                  }
                  className="bg-[#1a572b] font-semibold hover:bg-[#1a572b] py-3 mt-4 text-sm text-white uppercase w-full"
                >
                  Place Order
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}



 