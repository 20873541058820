import React, { createContext, useContext, useEffect, useState } from "react";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../Config/Config";

const DocumentContext = createContext();

export const useHomedata = () => useContext(DocumentContext);

export const HomedataProvider = ({ children }) => {
  const [documentData, setDocumentData] = useState(null);

  useEffect(() => {
    const docRef = doc(db, "home_data", 'home_data'); 
    const unsubscribe = onSnapshot(docRef, (snapshot) => {
      if (snapshot.exists()) {
        setDocumentData(snapshot.data());
      } else {
        console.log("No such document!");
      }
    });

    return () => unsubscribe(); 
  }, []);

  return (
    <DocumentContext.Provider value={documentData}>
      {children}
    </DocumentContext.Provider>
  );
};
