import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { auth, db } from "../../Admin/Config/Config";
import { addDoc, collection, doc, getDocs, updateDoc } from "firebase/firestore";
import { useToast } from "../../Toast.jsx/Toast";
export default function BillingAddress() {
  const location = useLocation();
  const [addresses, setAddresses] = useState([]);
  const [address, setaddress] = useState({email: auth.currentUser.email, country:"Canada", state:"Ontario", city: "", unit_number: "", street: "", zip: ""});
  const {showSuccess} = useToast();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  // =============================== Fetch Countries ================================ //
    const handleCountryChange = (e) => {
      const countryName = e.target.value;
        setaddress({...address, country: countryName});
      
    };
// =============================== Fetch Addresses ================================ //
  useEffect(() => {
    fetchAddress();
  }, []);

  const fetchAddress = async () => {
    setAddresses([]);
    const ref = collection(db, "users", auth.currentUser.uid, "address");
    const querySnapshot = await getDocs(ref);
    const add = [];
    querySnapshot.forEach((doc) => {
      add.push({ id: doc.id, ...doc.data() });
    });
    setAddresses(add);
  };

// =============================== Handle Address Change ================================ //
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [isNewAddress, setIsNewAddress] = useState(true);
  const handleAddressChange = (e) => {
    const selected = addresses.find(
      (address) => address.id === e.target.value
    );
    if (selected) {
      setSelectedAddress(e.target.value);
      setaddress(selected);
    }
  };
  // =============================== Handle Delete Address ================================ //

  const handleNewAddressToggle = () => {
    setIsNewAddress(!isNewAddress);
    if (isNewAddress) {
      setSelectedAddress("");
      setaddress({email: auth.currentUser.email, country:"", state:""});
    }
  };
// =============================== Handle Submit ================================ //
  const handleSubmit = async(e) => {
    e.preventDefault();
    setLoading(true);
    if(!selectedAddress){
      await addDoc(
        collection(db, "users", auth.currentUser.uid, "address"),
        address
      );
      await updateDoc(
        doc(db, "users", auth.currentUser.uid),
        {
          mobile: address.mobile,
        }
      );
      setLoading(false);
      // Navigate to the checkout page
      navigate("/checkout", {
        state: {
          address: address,
          promoValues: location.state.promoValues,
          appliedPromo: location.state.appliedPromo,
        },
      });
    } else {
      // Navigate to the checkout page
      navigate("/checkout", {
        state: {
          address: address,
          promoValues: location.state.promoValues,
          appliedPromo: location.state.appliedPromo,
        },
      });
    }
  };
  // ============================= Format Address ================================ //
  const formatAddress = (address) => {
    const { name, street, city, state, zip, country } = address;
    return `${name ? name + ", " : ""}${street ? street + ", " : ""}${
      city ? city + ", " : ""
    }${state ? state + ", " : ""}${zip ? zip + ", " : ""}${
      country ? country : ""
    }`.trim();
  };

  // ============================= Save Address ================================ //
  const onAddressSave = () => {
    if(selectedAddress){
      const ref = doc(
        db,
        "users",
        auth.currentUser.uid,
        "address",
        selectedAddress
      );
      updateDoc(ref, address);
      fetchAddress();
      showSuccess("Address updated successfully");
    } else {

      const ref = collection(
        db,
        "users",
        auth.currentUser.uid,
        "address",
      );
      addDoc(ref,address)
      showSuccess("Address updated successfully");

    }
  }

  return (
    <div className="font-sans px-4 bg-white p-4">
      <div className="max-w-7xl px-4 mx-auto">
        <div className="py-6">
          <div className="grid md:grid-cols-3 gap-4 border-gray-300 pb-4 mb-4">
            <div>
              <h3 className="text-3xl font-bold text-gray-500">01</h3>
              <h3 className="text-lg font-medium text-gray-800 mt-1">
                Shipping Address Details
              </h3>
            </div>
            <div className="md:col-span-2">
              <form onSubmit={handleSubmit}>
                <div className="grid sm:grid-cols-2 gap-4">
                  <div className="sm:col-span-2">
                    <label
                      className="text-sm text-gray-800 flex items-center mb-2"
                      htmlFor="name"
                    >
                      Name{" "}
                      <span className="text-red-500 text-xs ml-1">{`*`}</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Name (Required)"
                      value={address.name}
                      onChange={(e) =>
                        setaddress({ ...address, name: e.target.value })
                      }
                      className="px-4 py-3 bg-white text-gray-800 w-full text-sm border-2 rounded-md focus:border-green-800 outline-none"
                      required
                    />
                  </div>
                  <div className="sm:col-span-2">
                    <label
                      className="text-sm text-gray-800 flex items-center mb-2"
                      htmlFor="mobile"
                    >
                      Mobile Number{" "}
                      <span className="text-red-500 text-xs ml-1">{`*`}</span>
                    </label>
                    <div className="flex">
                      <input
                        type="text"
                        value="+1"
                        readOnly
                        className="px-4 py-3 bg-gray-50 text-gray-800 text-sm border-2 border-gray-200 rounded-l-md focus:border-green-800 outline-none"
                        style={{ width: "4rem" }}
                      />
                      <input
                        type="tel"
                        placeholder="Enter Mobile Number"
                        value={address.mobile}
                        onChange={(e) =>
                          setaddress({ ...address, mobile: e.target.value })
                        }
                        className="px-4 py-3 bg-white text-gray-800 w-full text-sm border-2 border-gray-300 rounded-r-md focus:border-green-800 outline-none"
                        required
                      />
                    </div>
                  </div>

                  <div>
                    <label
                      className="text-sm text-gray-800 flex items-center mb-2"
                      htmlFor="mobile"
                    >
                      Street Adrress{" "}
                      <span className="text-red-500 text-xs ml-1">{`*`}</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Street address"
                      value={address.street}
                      onChange={(e) =>
                        setaddress({
                          ...address,
                          street: e.target.value,
                        })
                      }
                      required
                      className="px-4 py-3 bg-white text-gray-800 w-full text-sm border-2 rounded-md focus:border-green-800 outline-none"
                    />
                  </div>
                  <div>
                    <label
                      className="text-sm text-gray-800 flex items-center mb-2"
                      htmlFor="mobile"
                    >
                      City
                      <span className="text-red-500 text-xs ml-1"></span>
                    </label>
                    <input
                      type="text"
                      placeholder="City"
                      value={address.city}
                      onChange={(e) =>
                        setaddress({ ...address, city: e.target.value })
                      }
                      className="px-4 py-3 bg-white text-gray-800 w-full text-sm border-2 rounded-md focus:border-green-800 outline-none"
                    />
                  </div>
                  <div>
                    <label
                      className="text-sm text-gray-800 flex items-center mb-2"
                      htmlFor="mobile"
                    >
                      Unit Number{" "}
                      <span className="text-red-500 text-xs ml-1"></span>
                    </label>
                    <input
                      type="text"
                      placeholder="Unit Number"
                      value={address.unit_number}
                      onChange={(e) =>
                        setaddress({ ...address, unit_number: e.target.value })
                      }
                      className="px-4 py-3 bg-white text-gray-800 w-full text-sm border-2 rounded-md focus:border-green-800 outline-none"
                    />
                  </div>

                  <div>
                    <label
                      className="text-sm text-gray-800 flex items-center mb-2"
                      htmlFor="mobile"
                    >
                      Zip Code{" "}
                      <span className="text-red-500 text-xs ml-1">{`*`}</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Zip Code"
                      value={address.zip}
                      onChange={(e) =>
                        setaddress({ ...address, zip: e.target.value })
                      }
                      required
                      className="px-4 py-3 bg-white text-gray-800 w-full text-sm border-2 rounded-md focus:border-green-800 outline-none"
                    />
                  </div>
                  <div>
                    <label
                      className="text-sm text-gray-800 flex items-center mb-2"
                      htmlFor="mobile"
                    >
                      Country
                      <span className="text-red-500 text-xs ml-1">{`*`}</span>
                    </label>

                    <select
                      value={address.country}
                      onChange={handleCountryChange}
                      required
                      className="px-4 py-3 bg-white text-gray-800 w-full text-sm border-2 rounded-md focus:border-green-800 outline-none appearance-none"
                    >
                      <option value={"Canada"}>Canada</option>
                    </select>
                  </div>
                  <div>
                    <label
                      className="text-sm text-gray-800 flex items-center mb-2"
                      htmlFor="mobile"
                    >
                      State{" "}
                      <span className="text-red-500 text-xs ml-1">{`*`}</span>
                    </label>

                    <select
                      value={address.state}
                      required
                      onChange={(e) =>
                        setaddress({ ...address, state: e.target.value })
                      }
                      className="px-4 py-3 bg-white text-gray-800 w-full text-sm border-2 rounded-md focus:border-green-800 outline-none appearance-none"
                    >
                      <option key={address.id} value={`Ontario`}>
                        {`Ontario`}
                      </option>
                    </select>
                  </div>
                  {isNewAddress ? (
                    <div className="sm:col-span-2 text-right">
                      <button
                        type="button"
                        onClick={handleNewAddressToggle}
                        className="text-sm font-semibold text-green-800 underline"
                      >
                        Choose from existing addresses
                      </button>
                    </div>
                  ) : (
                    <>
                      <div className="sm:col-span-2">
                        <label className="block text-sm font-medium text-gray-700 mb-2">
                          Choose Address:
                        </label>
                        <div className="relative">
                          <select
                            value={selectedAddress}
                            onChange={handleAddressChange}
                            className="px-4 py-3 bg-white text-gray-800 w-full text-sm border-2 rounded-md focus:border-green-800 outline-none appearance-none"
                          >
                            <option value="" disabled>
                              Select an address
                            </option>
                            {addresses &&
                              addresses.map((add) => {
                                return (
                                  <option value={add.id}>
                                    {formatAddress(add)}
                                  </option>
                                );
                              })}
                          </select>

                          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                            <svg
                              className="h-5 w-5 text-gray-400"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M19 9l-7 7-7-7"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                      <div className="sm:col-span-2 text-right">
                        <button
                          type="button"
                          onClick={handleNewAddressToggle}
                          className="text-sm font-semibold text-green-800 underline"
                        >
                          Enter a new address
                        </button>
                        &ensp;
                        {!isNewAddress && selectedAddress ? (
                          <button
                            type="button"
                            onClick={onAddressSave}
                            className="text-sm font-semibold text-green-800 underline"
                          >
                            Save address
                          </button>
                        ) : null}
                      </div>
                    </>
                  )}
                </div>

                <button
                  type="submit"
                  className="mt-6 w-full px-8 py-3 bg-green-800 text-white rounded-md text-sm font-semibold hover:bg-green-800"
                >
                  {loading ? (
                    <div className="flex items-center justify-center">
                      <svg
                        className="animate-spin h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                        ></path>
                      </svg>
                      <span className="ml-2 text-white">Loading...</span>
                    </div>
                  ) : (
                    "Continue to checkout"
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
