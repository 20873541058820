import React, { useEffect, useState, useRef } from "react";
import { useCartContext } from "./CartContext";
import { app, auth, db } from "../../Admin/Config/Config";
import Loading from "../../assets/Loading/Loading";
import { useLoginPopup } from "../../Auth/LoginContext";
import { useNavigate } from "react-router-dom";
import Emptycart from "./Emptycart";
import {  arrayUnion, collection, doc, getDocs, orderBy, query, updateDoc, where } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useToast } from "../../Toast.jsx/Toast";
import Confetti from 'react-confetti';
import './css/Product.css';

export default function Cart() {
  const { cart, fetchCart, updateQuantity, removeFromCart,emptyCart } = useCartContext();
  const [quantityLoading, setQuantityLoading] = useState(false);
  const [quantityId, setQuantityId] = useState("");
  const { openPopup } = useLoginPopup();
  const { showSuccess, showWarning } = useToast();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [totalAmount, setTotalAmount] = useState(0);
  // =============================== Cart Fetching =============================== //

  useEffect(() => {
    if (auth.currentUser) {
      const fetch = async () => {
       await fetchCart(auth.currentUser.uid);
      };
      fetch();
    } else {
        const fetch = async () => {
            await fetchCart("");
        };
      fetch();
      //openPopup();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(()=>{
    if(cart){
    if(cart.length===0&&emptyCart===true){
      setLoading(false);
    } else if(cart.length>0&&emptyCart===false){
      setLoading(false);
    }
  }
  },[cart,emptyCart])
  // =============================== Quantity handler =============================== //
  const quantityChangeHandler = async (id, value) => {
    removePromo()
    const result = await updateQuantity(auth.currentUser?auth.currentUser.uid:'', id, value);
    if (result) {
      setQuantityLoading(false);
      setQuantityId("");
    } else {
      setQuantityId("");
      setQuantityLoading(false);
    }
  };
  const handleRemove = async (id) => {
    removePromo()
    setQuantityLoading(true);
    setQuantityId(id);
    const result = await removeFromCart(auth.currentUser?auth.currentUser.uid:'', id);
    if (result) {
      setQuantityId("");
      setQuantityLoading(false);
    } else {
      setQuantityId("");
      setQuantityLoading(false);
    }
  };
  const [taxAmount, settaxAmount] = useState(0);
  useEffect(() => {
    if (cart) {
      let total = 0;
      let taxTotal = 0;
      cart.forEach((item) => {
        total += item.offer_price * item.cart_quantity;
        if (item.tax_applicable === true) {
          taxTotal += item.offer_price * item.cart_quantity;
        }
      });
      const tax = ((taxTotal / 100) * 13).toFixed(2);
      settaxAmount(parseFloat(tax));
      setTotalAmount(parseFloat(total.toFixed(2)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart]);

  const increment = (id, qty) => {
    removePromo()
    setQuantityLoading(true);
    setQuantityId(id);
    quantityChangeHandler(id, parseInt(qty) + 1);
  };

  const decrement = async (id, qty) => {
    removePromo();
    setQuantityLoading(true);
    setQuantityId(id);
    if (qty > 1) {
      quantityChangeHandler(id, parseInt(qty) - 1);
    } else {
      const result = await removeFromCart(
        auth.currentUser?auth.currentUser.uid:'',
        id
      );
      if (result) {
        setQuantityLoading(false);
        setQuantityId("");
      } else {
        setQuantityLoading(false);
        setQuantityId("");
      }
    }
  };
  // =============================== Fetch Promo Code ================================ //
  const [promoList, setPromoList] = useState([]);
  const fetchPromoCode = async () => {
    const ref = collection(db, "promocodes");
    const q = query(
      ref,
      where("status", "==", true),
      where("eligible_customers", "array-contains-any", [
        "common",
        auth.currentUser.uid,
      ]),
      orderBy("order", "asc")
    );
    const querySnapshot = await getDocs(q);
    const promocode = [];
    querySnapshot.forEach((doc) => {
      promocode.push({ id: doc.id, ...doc.data() });
    });
    setPromoList(promocode);
  };
  useEffect(() => {
    if (auth.currentUser) {
      fetchPromoCode();
    }
  }, []);
  // =============================== Apply Promo Code ================================ //

  const [appliedPromo, setAppliedPromo] = useState(null);
  const [promoValues, setPromoValues] = useState(null);
  const [promoLoading, setPromoLoading] = useState(false);
  const [promoId, setPromoId] = useState(null);
  const applyPromoCode = async (code, item) => {
    if (auth.currentUser) {
      setPromoLoading(true);
      setPromoId(code);
      const functions = getFunctions(app, "us-central1");
      const additem = httpsCallable(functions, "checkPromo");
      additem({ promoId: code, order: false, promoFlag: true }).then(
        (result) => {
          const data = result.data;
          if (data.code === 200) {
            setPromoValues({
              discount: data.discount,
              final: data.final,
            });
            setAppliedPromo(item);
            setPromoLoading(false);
            showSuccess(data.message);
            setPromoId(null);
          } else {
            setAppliedPromo(null);
            setAppliedPromo(null);
            setPromoLoading(false);
            showWarning(data.message);
            setPromoId(null);
          }
        }
      );
    } else {
      openPopup();
    }
  };
  const handleClick = () => {
    // Navigate to the checkout page
    if(auth.currentUser) {
      navigate("/billing-address", {
        state: {
          promoValues: appliedPromo ? promoValues : "",
          appliedPromo: appliedPromo ? appliedPromo : "",
          // shippingCharges: shippingCharges,
        },
      });
    } else {
      openPopup();
    }
  };
  // =============================== Remove Promo Code ================================ //

  const removePromo = () => {
    setAppliedPromo(null);
    setPromoValues(null);
  };

  const [showConfetti, setShowConfetti] = useState(false);

  React.useEffect(() => {
    if (appliedPromo) {
      setShowConfetti(true);

      setTimeout(() => setShowConfetti(false), 6000);
    }
  }, [appliedPromo]);
  // ========================= Save to whishlist ========================= //
  const toggleLike = async ( id, liked) => {
    if (auth.currentUser) {
      const userId = auth.currentUser.uid;
      if(!liked){
      await updateDoc(doc(db, "products", id), {
        liked_users: arrayUnion(userId),
      });
      showSuccess("Product added to wishlist");
      }
      else{
        showSuccess("Product added to wishlist");
      }
    } else {
      openPopup();
    }
  };

    const promoContainerRef = useRef(null);

  const scrollRight = () => {
    if (promoContainerRef.current) {
      promoContainerRef.current.scrollBy({ left: 200, behavior: "smooth" });
    }
  };

  // const scrollLeft = () => {
  //   if (promoContainerRef.current) {
  //     promoContainerRef.current.scrollBy({ left: -200, behavior: "smooth" });
  //   }
  // };


  
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          {!emptyCart ? (
            <div class="container mx-auto mt-2 ">
              <div class="sm:flex max-w-8xl my-6 ">
                <div class="  w-full   sm:w-3/4 bg-white px-4 ">
                  <div class="flex justify-between  pb-8">
                    <h1 class="font-semibold text-[#1a572b] text-lg">
                      Shopping Cart
                    </h1>
                    {cart && cart.length > 0 ? (
                      <h2 class="font-semibold text-[#1a572b] text-lg">
                        {cart.length} Items
                      </h2>
                    ) : null}
                  </div>
                  {cart &&
                    cart.map((item) => (
                      <div>
                        {item.stock < 1 ? (
                          <div className="relative inline-block">
                            <p className="text-xs leading-3 text-white font-bold bg-red-800 px-4 py-2  absolute top-4 left-4 z-10 whitespace-nowrap">
                              OUT OF STOCK
                            </p>
                          </div>
                        ) : null}
                        <div className="relative">
                          {((quantityLoading === true &&
                            item.id === quantityId) ||
                            item.stock < 1) && (
                            <div className="absolute inset-0 bg-white opacity-50 pointer-events-none z-10"></div>
                          )}

                          <div
                            className={`relative md:flex border border-gray-400 border-dotted rounded-xl items-stretch py-4 md:py-10 lg:py-8 px-6 ${
                              (quantityLoading === true &&
                                item.id === quantityId) ||
                              item.stock < 1
                                ? "opacity-50"
                                : ""
                            }`}
                          >
                            <div className="md:w-4/12 py-6 2xl:w-1/4 w-full">
                              <img
                                src={item.thumb_url}
                                alt="product"
                                className="w-auto h-24 mx-auto object-center object-cover md:block hidden"
                              />
                              <img
                                src={item.thumb_url}
                                alt="product"
                                className="md:hidden w-auto mx-auto h-24 object-contain mb-4 rounded"
                              />
                            </div>
                            <div className="md:pl-3 md:w-8/12 2xl:w-3/4 flex flex-col justify-center">
                              <p className="text-base font-black leading-none text-gray-800 pt-2">
                                {item.product_name}&ensp;
                                {item.tax_applicable === true
                                  ? `(HST Applicable)`
                                  : ""}
                              </p>
                              <div className="flex items-center justify-between w-full">
                                <p className="  text-xs leading-3 text-gray-800 md:pt-0 pt-4">
                                  {item.brand}
                                </p>
                                <div className="flex items-center justify-center border border-gray-300 rounded-lg p-1.5 bg-white mt-2">
                                  <button
                                    disabled={
                                      (quantityLoading &&
                                        item.id === quantityId) ||
                                      item.stock < 1
                                    }
                                    onClick={() =>
                                      decrement(
                                        item.productId,
                                        item.cart_quantity
                                      )
                                    }
                                    className="w-10 h-10 text-white bg-green-800 rounded-lg hover:bg-green-700"
                                  >
                                    -
                                  </button>
                                  <input
                                    type="number"
                                    value={item.cart_quantity}
                                    readOnly
                                    className="w-16 text-center border-none focus:outline-none text-lg font-semibold text-gray-700"
                                    style={{ textAlign: "center" }}
                                  />
                                  <button
                                    disabled={
                                      (quantityLoading &&
                                        item.id === quantityId) ||
                                      item.stock < 1
                                    }
                                    onClick={() =>
                                      increment(
                                        item.productId,
                                        item.cart_quantity
                                      )
                                    }
                                    className="w-10 h-10 text-white bg-green-800 rounded-lg hover:bg-green-700"
                                  >
                                    +
                                  </button>
                                </div>
                              </div>
                              <div className="flex items-center justify-between pt-5">
                                <div className="flex items-center">
                                  <p
                                    onClick={() =>
                                      toggleLike(item.productId, item.liked)
                                    }
                                    className="text-xs leading-3 underline text-gray-800 cursor-pointer"
                                  >
                                    Add to Wishlist
                                  </p>
                                </div>
                                {item.offer_price && item.cart_quantity ? (
                                  <p className="font-medium mr-2 mt-1 leading-none text-lg text-black">
                                    ${" "}
                                    {(
                                      parseFloat(item.offer_price) *
                                      parseInt(item.cart_quantity)
                                    ).toFixed(2)}{" "}
                                  </p>
                                ) : null}
                              </div>
                            </div>
                          </div>

                          <div
                            className="absolute top-2 right-2 z-100 flex items-center space-x-1 text-red-800 bg-red-200 hover:bg-red-100 cursor-pointer p-1 rounded-full shadow-lg transition duration-200 ease-in-out transform hover:scale-105"
                            onClick={() => handleRemove(item.productId)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-4 h-4"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              strokeWidth="2"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                              />
                            </svg>
                          </div>
                        </div>

                        <br />
                      </div>
                    ))}
                  <a
                    href="/"
                    className="text-green-700 ml-2 mt-2 flex items-center cursor-pointer"
                  >
                    Continue Shopping
                    <svg
                      className="w-4 h-4 mt-1 ml-1"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fillRule="evenodd"
                        d="M12.293 3.293a1 1 0 011.414 0l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414-1.414L15.586 10H3a1 1 0 110-2h12.586l-3.293-3.293a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </a>
                  <br />
                </div>
                {cart ? (
                  <div
                    id="summary"
                    class=" w-full   sm:w-1/4   md:w-1/2     px-8 pb-8 "
                  >
                    <h1 class="font-semibold text-2xl border-b pb-2">
                      Order Summary
                    </h1>
                    <div class="flex justify-between mt-8">
                      <span class="font-semibold text-sm uppercase">
                        Items {cart.length}
                      </span>
                      <span class="font-semibold text-green-800 text-sm">
                        $&nbsp;{totalAmount}
                      </span>
                    </div>
                    {appliedPromo && (
                      <div class="flex justify-between mt-6 mb-5">
                        <span class="font-semibold text-sm uppercase">
                          Promo Code Discount
                        </span>
                        <span class="font-semibold text-green-800 text-sm">
                          $ &nbsp;{promoValues ? promoValues.discount : 0}
                        </span>
                      </div>
                    )}
                    <div>
                      {appliedPromo && (
                        <>
                          {showConfetti && <Confetti />}
                          <div className="">
                            <div className="flex bg-green-50 text-green-800 border border-dashed border-green-500 rounded-lg p-4 justify-between items-center mt-2">
                              <p className="text-xs text-green-800">
                                {appliedPromo.code} Applied 🎉
                              </p>
                              <button
                                onClick={() => removePromo()}
                                className="text-red-500 text-xs font-semibold hover:underline"
                              >
                                Remove
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                    </div>

                    <div className="mt-4">
                    
                      <label
                        htmlFor="promo"
                        className="font-semibold inline-block mb-1 text-sm uppercase"
                      >
                        Promo Code
                      </label>
                      {!auth.currentUser ? (
                        <div className="flex gap-2 overflow-x-auto scrollbar-hide w-full">
                          <div className="flex justify-between items-center">
                            <span className="font-semibold text-sm">
                              Please{" "}
                              <span
                                style={{
                                  cursor: "pointer",
                                  textDecoration: "underline",
                                }}
                                onMouseEnter={(e) =>
                                  (e.currentTarget.style.color = "blue")
                                }
                                onMouseLeave={(e) =>
                                  (e.currentTarget.style.color = "black")
                                }
                                onClick={() => {
                                  openPopup();
                                }}
                              >
                                Login
                              </span>{" "}
                              to apply promo code
                            </span>
                          </div>
                        </div>
                      ) : null}

                      <div className="relative w-full">
                        {/* Promo List */}
                        <div
                          ref={promoContainerRef}
                          className="flex gap-2 overflow-x-auto w-full"
                        >
                          {promoList && promoList.length > 0
                            ? promoList
                                .filter(
                                  (item) =>
                                    !appliedPromo || appliedPromo.id !== item.id
                                )
                                .map((item) => (
                                  <div key={item.id} className="flex-shrink-0">
                                    <div
                                      className={`mt-2 rounded-lg px-4 py-3 w-72 bg-green-100 text-sm text-green-800 border border-dashed border-green-500 relative ${
                                        promoLoading && promoId === item.id
                                          ? "opacity-30"
                                          : ""
                                      }`}
                                    >
                                      <div className="flex justify-between items-center">
                                        <span className="font-semibold text-sm">
                                          {item.code}
                                        </span>
                                        <button
                                          disabled={promoLoading}
                                          onClick={() =>
                                            applyPromoCode(item.id, item)
                                          }
                                          className="px-3 py-1 bg-green-900 text-white text-xs rounded-lg hover:bg-green-500"
                                        >
                                          {promoId === item.id
                                            ? "Applying..."
                                            : "Apply"}
                                        </button>
                                      </div>
                                      <p className="text-xs mt-2">
                                        {item.desc}
                                      </p>
                                    </div>
                                  </div>
                                ))
                            : null}
                        </div>

                        {/* Left Indicator */}
                        {/* <div
        className="absolute top-1/2 left-0 transform -translate-y-1/2 bg-white rounded-full shadow-md p-2 cursor-pointer"
        onClick={scrollLeft}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6 text-green-800"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15 19l-7-7 7-7"
          />
        </svg>
      </div> */}

                        {/* Right Indicator */}
                        {promoList && promoList.length > 1 && (
                          <div
                            className="absolute top-1/2 right-0 transform -translate-y-1/2 bg-white rounded-full shadow p-1 cursor-pointer"
                            onClick={scrollRight}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-6 w-6 text-green-800"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              strokeWidth={2}
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M9 5l7 7-7 7"
                              />
                            </svg>
                          </div>
                        )}
                      </div>
                    </div>

                    <div class="border-t mt-4">
                      <div class="flex font-semibold justify-between mt-4 text-sm uppercase">
                        <span>Sub total</span>
                        {cart.length > 0 && totalAmount ? (
                          <span>
                            $&nbsp;
                            {totalAmount}
                            {/* {promoValues ? (parseFloat(promoValues.final)-parseFloat(taxAmount)).toFixed(2) : totalAmount} */}
                          </span>
                        ) : null}
                      </div>
                      {taxAmount > 0 && (
                        <div class="flex font-semibold justify-between mt-4 text-sm uppercase">
                          <span>HST</span>
                          {cart.length > 0 && totalAmount ? (
                            <span>
                              $&nbsp;
                              {taxAmount ? taxAmount : 0}
                            </span>
                          ) : null}
                        </div>
                      )}
                      {promoValues && promoValues.discount > 0 && (
                        <div class="flex font-semibold justify-between mt-4 text-sm uppercase">
                          <span>Discount</span>

                          <span>
                            $&nbsp; {promoValues ? promoValues.discount : 0}
                          </span>
                        </div>
                      )}
                      <div class="flex font-semibold justify-between mt-6 text-sm uppercase">
                        <span>Total cost</span>
                        {cart.length > 0 && totalAmount ? (
                          <span>
                            $&nbsp;
                            {promoValues
                              ? promoValues.final.toFixed(2)
                              : parseFloat(
                                  parseFloat(totalAmount) +
                                    parseFloat(taxAmount)
                                ).toFixed(2)}
                          </span>
                        ) : null}
                      </div>
                      <div className="w-full mt-4 ">
                        <button
                          onClick={handleClick}
                          disabled={cart.some((item) => item.stock < 1)}
                          className={`${
                            cart.some((item) => item.stock < 1)
                              ? "bg-gray-400 cursor-not-allowed opacity-60"
                              : "bg-[#1a572b] hover:bg-[#155f2b]"
                          } font-semibold py-3 text-sm text-white uppercase w-full transition duration-300 ease-in-out`}
                        >
                          Checkout
                        </button>
                        {cart.some((item) => item.stock < 1) && (
                          <p className="text-red-500 text-xs mt-2 ">
                            The item you selected is not available.
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          ) : (
            <Emptycart />
          )}
        </>
      )}
    </>
  );
}
