import React, { useEffect, useState } from "react";
import { useHomedata } from "../../Admin/Homedata/HomedataProvider";
import img1 from './assets/del.png'
import img2 from './assets/24.png'
import img3 from './assets/lp.png'

export default function Features() {
  const  homeData  = useHomedata();
  const [features, setFeatures] = useState([]);
  useEffect(() => {
    if(homeData){
    setFeatures([
      {
        title: homeData.highlight1_title,
        description: homeData.highlight1_sub_title,
        image: homeData.highlight1image,
        is_true: homeData.is_highlight1,
        dummy_img: img1
      },
      {
        title: homeData.highlight2_title,
        description: homeData.highlight2_sub_title,
        image: homeData.highlight2image,
        is_true: homeData.is_highlight2,
        dummy_img: img2
      },
      {
        title: homeData.highlight3_title,
        description: homeData.highlight3_sub_title,
        image: homeData.highlight3image,
        is_true: homeData.is_highlight3,
        dummy_img: img3
      },
    ]);
  }
  },[homeData])


  return (
    <div className="container mx-auto py-2 px-6 max-w-8xl bg-white rounded-xl text-center">
      <div className="grid grid-cols-3 gap-4">
        {features.length &&
          features.map((feature, index) => (
            <>
            {feature.is_true?
              <div key={index} className="p-4 bg-green-50 rounded-xl">
                <div className="flex flex-col items-center">
                  <div className="w-full mb-4">
                    <img
                      src={feature.image?feature.image:feature.dummy_img}
                      className="w-16 h-10 sm:w-6 sm:h-6 lg:w-24 lg:h-24 object-cover mx-auto"
                      alt={feature.title}
                    />
                  </div>
                  <div className="w-full text-center">
                    <h3 className="text-[10px] sm:text-[8px] lg:text-[18px] text-[#1a572b] font-semibold mb-2">
                      {feature.title}
                    </h3>

                    <p className="text-[8px] sm:text-[12px] lg:text-[14px] text-gray-600">
                      {feature.description}
                    </p>
                  </div>
                </div>
              </div>
              :null}
            </>
          ))}
      </div>
    </div>
  );
}
